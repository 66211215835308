import React, { Component } from "react";
import ContactForm from "./ContactForm";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Animated } from "react-animated-css";

class ContactUs extends Component {
  state = {};

  constructor()
  {
    super();
    document.getElementById("body").className = "bg-img bg-margin";
  }
  
  render() {
    return (
      <React.Fragment>
        <Container className="p-5">
          <Row>
            <Col>
              <p>&nbsp;</p>
            </Col>
          </Row>
          <Row>
            <Col>
              <h3
                data-aos="fade-up-right"
                data-aos-offset="200"
                data-aos-easing="ease-in-out"
                data-aos-duration="1000"
                data-aos-anchor-placement="top-bottom"
              >
                CONTACT US
              </h3>
            </Col>
          </Row>
          <Row>
            <Col>
              <p>&nbsp;</p>
            </Col>
          </Row>
          <Row>
            <Col className="my-3">
              <ContactForm></ContactForm>
            </Col>
            <Col className="mx-3 my-3">
              {/* inner section */}
              {/* contact infomation */}
              <Row>
                <Col>
                  <h4>Contact Information</h4>
                </Col>
              </Row>
              <Row>
                <Col>
                  <h6>63/3/1, Piliyandala Road,</h6>
                </Col>
              </Row>
              <Row>
                <Col>
                  <h6>Maharagama, Sri Lanka.</h6>
                </Col>
              </Row>
              <Row>
                <Col>
                  <h6>0094 117 580 600</h6>
                </Col>
              </Row>
              <Row>
                <Col>
                  <h6>
                    <a href="mailto:operations@cloudzleisure.com " className="d-block">
                    operations@cloudzleisure.com 
                    </a>
                  </h6>
                </Col>
              </Row>
              {/* contact infomation */}
              <Row>
                <Col>&nbsp;</Col>
              </Row>
              {/* cooperate office */}
              <Row>
                <Col>
                  <h4>Corporate Office</h4>
                </Col>
              </Row>
              <Row>
                <Col>
                  <h6>11, Hunter Street, Kew, VIC,</h6>
                </Col>
              </Row>
              <Row>
                <Col>
                  <h6>Melbourne,</h6>
                </Col>
              </Row>
              <Row>
                <Col>
                  <h6>Australia</h6>
                </Col>
              </Row>
              <Row>
                <Col>
                  <h6>Tel: 0061 432 434 565</h6>
                </Col>
              </Row>
              {/* cooperate office */}
              <Row>
                <Col>&nbsp;</Col>
              </Row>
              {/* social networks */}
              <Row>
                <Col>
                  <h4>Follow us on Social Media</h4>
                </Col>
              </Row>
              <Row>
                <Col className="mx-6 text-center">
                  <ul className="list-unstyled social-network">
                    <li className="social-li">
                      <a
                        href="https://www.facebook.com/pages/category/Tour-Agency/Cloudz-Leisure-Holidays-555931101522544/"
                        title=""
                        className="fa-icon"
                        target="_blank"
                      >
                        <i class="fa fa-facebook"></i>
                      </a>
                    </li>
                  </ul>
                </Col>
              </Row>

              {/* social networks */}
            </Col>
          </Row>
          <Row>
            <Col>
              <p>&nbsp;</p>
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    );
  }
}

export default ContactUs;

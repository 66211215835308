import React, { Component } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link } from "react-router-dom";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Modal from "react-bootstrap/Modal";
import TourBookingForm from "./TourBookingForm";
import { Button } from "react-bootstrap";

class CoverSriLankaTour extends Component {
  state = {
    showBookingForm: false
  };

  showForm = () =>
    this.setState({
      showBookingForm: true
    });

  hideForm = () =>
    this.setState({
      showBookingForm: false
    });


  render() {
    return (
      <React.Fragment>
        <Container className="p-4">
          <Row>
            <Col>
              <Breadcrumb>
                <Breadcrumb.Item>
                  <Link to="/" className=" active  text-left">
                    Home
                  </Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                  <Link to="/mostfamoustours" className=" active  text-left">
                    Most Famous Tours
                  </Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item active>Cover Sri Lanka Tour</Breadcrumb.Item>
              </Breadcrumb>
            </Col>
          </Row>
          <Row>
            <Col>
              <h3>Cover Sri Lanka Tour</h3>
            </Col>
          </Row>
          <Row>
            <Col>
              <p>&nbsp;</p>
            </Col>
          </Row>
          {/* Day 01 */}
          <Row>
            <Col className="text-left">
              <h5>
                {" "}
                <strong>
                  1. Arrival Day – Negombo and back roads cycling{" "}
                </strong>
              </h5>
              <p className="text-justify">
                Arrive into Bandaranaike International Airport and be
                transferred to our hotel on nearby Negombo Beach. In the
                afternoon, we’ll take our first bike ride along the old Dutch
                Canal and through scenic back roads. We’ll also stop at a local
                produce stand to sample some of Sri Lanka’s exotic fruit and
                drink fresh king coconut juice.
              </p>
            </Col>
          </Row>
          {/* Day 02 */}
          <Row>
            <Col className="text-left">
              <h5>
                <strong>2. Kalpitiya and Puttalam Lagoon</strong>
              </h5>
              <p className="text-justify">
                A morning ride up the coast by car brings us to the town of
                Kalpitiya, on a thin strip of sand between the Indian Ocean and
                Puttalam Lagoon.  After lunch, we’ll head by truck to the launch
                point for an incredible afternoon of downwind lagoon kayaking
                past desert islands, pristine beaches and mangrove lagoons.
              </p>
            </Col>
          </Row>
          {/* Day 03 */}
          <Row>
            <Col className="text-left">
              <h5>
                <strong>
                  3. Wilpattu National Park, Wind/Kite Surfing if qualified
                </strong>
              </h5>
              <p className="text-justify">
                Our destination this morning is the beautiful mangrove lagoons
                and savannas of Wilpattu National Park, which we’ll spend the
                morning kayaking through.  Expect to see plenty of monkeys,
                water birds and monitor lizards, with a chance of seeing
                elephants and even leopards as well.  This is an incredibly
                beautiful and peaceful journey through pristine wildlands.  Free
                afternoon at Wilpattu Nature Resort, where we’ll also spend the
                night. Experienced kite or wind surfers can spend the day on
                these activities instead in Kalpitiya Lagoon with all equipment
                included (assuming there’s wind), and join the group by private
                car in the evening.
              </p>
            </Col>
          </Row>
          {/* Day 04 */}
          <Row>
            <Col className="text-left">
              <h5>
                <strong>4. Anuradhapura Ruins</strong>
              </h5>
              <p className="text-justify">
                A morning drive inland brings us to the ancient city of
                Anuradhapura, the capital of Sri Lanka for over 1000 years.
                 Cycling is the way to see Anuradhapura’s ruins and huge stupas,
                as well as its famous Bodhi tree, grown from a sapling of the
                tree in India which the Buddha sat under to attain
                enlightenment.  Anuradhapura’s holy sites are visited daily by
                thousands of devotees, making for a fascinating and colorful
                cultural experience.
              </p>
            </Col>
          </Row>
          {/* Day 05 */}
          <Row>
            <Col className="text-left">
              <h5>
                <strong>
                  5. Nilaveli Beach and Konaswaram Temple, Trincomalee
                </strong>
              </h5>
              <p className="text-justify">
                And now for something completely different – pristine Nilaveli
                Beach on Sri Lanka’s northeastern, predominantly Hindu
                coastline.  After some beach time and lunch, we’ll drive into
                the city of Trincomalee for a visit to one of Sri Lanka’s most
                important Hindu Temples, Konaswaram.  Stunningly situated on
                cliffs jutting out from the city, our walk takes us past an old
                Dutch fort and a large number of protected spotted deer before
                arriving at the large, colorful and vibrant temple.
              </p>
            </Col>
          </Row>
          {/* Day 06 */}
          <Row>
            <Col className="text-left">
              <h5>
                <strong>
                  6. Nilaveli Beach and Pigeon Island National Park   
                </strong>
              </h5>
              <p className="text-justify">
                Today we spend a full day snorkeling and sunbathing on Pigeon
                Island, a beautiful national park a short boat ride from
                Nilaveli beach.  Colorful coral, countless fish, octopus, sea
                turtles and even reef sharks are all on the menu at this great
                snorkeling spot. Included diving is also an option for those
                with certification.
              </p>
            </Col>
          </Row>
          {/* Day 07 */}
          <Row>
            <Col className="text-left">
              <h5>
                <strong>7. Dambulla Cave Temple     </strong>
              </h5>
              <p className="text-justify">
                Today we have a free morning on the beach, and then we journey
                back inland to the Dambulla Cave Temples, a UNESCO World
                Heritage Site.   The five cave temples sit 160 meters (528 ft.)
                above the surrounding countryside, and tell the story of the
                life of Buddha via countless old statues and wall paintings.
              </p>
            </Col>
          </Row>
          {/* Day 08 */}
          <Row>
            <Col className="text-left">
              <h5>
                <strong>
                  8.  Sigiriya Rock Fortress, Pidurangala Rock and Elephant
                  Safari
                </strong>
              </h5>
              <p className="text-justify">
                History and stunning views await us this morning as we climb the
                unique rock outcroppings of Sigiriya and Pidurangala.
                 Pidurangalala is famous for its massive reclining Buddha as
                well as the awesome view of Sigiriya and surroundings from its
                200-meter (660 ft) summit.  After taking in its 360 degree
                vista, we’ll head to the ancient fortress of Sigiriya, a
                UNESCO World Heritage Site, just down the road.  One of the best
                examples in the world of ancient urban planning, Sigiriya is a
                wonderland of boulder gardens, reflecting pools, frescoes and
                monumental sculpture.  Enjoy a guided tour of this geological
                and archaeological treasure before climbing back down for a
                traditional Sri Lankan curry buffet.
              </p>
              <p className="text-justify">
                After lunch, it’s time to check out some of Sri Lanka’s most
                impressive fauna as we head out on a jeep safari to view
                elephants, buffalo, deer, jackals, peacocks, eagles and other
                wildlife.  Elephants are, of course, the main attraction, and
                often more than 100 can be seen at a time near the area’s
                reservoirs and watering holes.  Most often, our destination is
                Kaudulla National Park, but it could be somewhere else nearby,
                based on recent elephant activity.
              </p>
            </Col>
          </Row>
          {/* Day 09 */}
          <Row>
            <Col className="text-left">
              <h5>
                <strong>9.  Polonnaruwa Ruins, Lake and Canal</strong>
              </h5>
              <p className="text-justify">
                this morning we set out on our mountain bikes to explore Sri
                Lanka’s best-preserved ruins, the stunning temple and palace
                complex of Polonnaruwa.  Also a former capital of Sri Lanka,
                Polonnaruwa’s monumental architecture dates mostly from the 12th
                and 13th centuries.  Towering Buddha statues, ancient
                inscriptions and ornate stone carvings and murals make this a
                true highlight of the trip.
              </p>
              <p className="text-justify">
                Our day doesn’t end there, though.  After lunch, we’ll head out
                onto the water to explore Polonnaruwa’s scenic lakeside and
                canal. Kingfishers, herons, hornbills and eagles are among the
                bird life we might spot, and giant water monitors are a common
                site in the canal.  There’s also a chance to see elephants on
                the far shore of the lake.
              </p>
            </Col>
          </Row>
          {/* Day 10 */}
          <Row>
            <Col className="text-left">
              <h5>
                <strong>
                  10. Kandy, Temple of the Tooth, Forest above Kandy
                </strong>
              </h5>
              <p className="text-justify">
                The cultural heart of Sri Lanka, the bustling city of Kandy, is
                our destination this morning.  After settling into our hotel,
                we’ll visit the most sacred site in Sri Lanka, the Temple of the
                Sacred Tooth.  The tooth is one of Lord Buddha’s, and is
                surrounded by beautiful Buddhist temples, as well as a couple of
                interesting museums.  After some free time to explore the city
                or stroll around Kandy’s scenic lake, we’ll set out for the
                forest above Kandy for a six kilometer hike to spot monkeys,
                birds and interesting flora which your guide will describe.
              </p>
            </Col>
          </Row>
          {/* Day 11 */}
          <Row>
            <Col className="text-left">
              <h5>
                <strong>
                  11. Kandy Botanical Gardens, Mahaweli River, Folk Dance
                  Performance
                </strong>
              </h5>
              <p className="text-justify">
                An early morning start brings us to the Kandy Botanical Gardens
                just outside of town.  These are the nicest and largest
                botanical gardens in the country, and contain lots of
                interesting tropical and subtropical species, as well as an
                orchid exhibition and a large native population of flying foxes
                and monkeys.  After the gardens and a buffet lunch, we hit the
                Mahaweli River for a kayak trip which will loop us around the
                gardens and back to the city of Kandy.  Keep an eye out for bird
                life as well as giant monitor lizards and jumping fish.
                 Afterwards, we’ll go back to our hotel, clean up a bit, and
                then attend a performance of traditional Kandyan music and
                dance.
              </p>
            </Col>
          </Row>
          {/* Day 12 */}
          <Row>
            <Col className="text-left">
              <h5>
                <strong>12.  Highlands Train and Biking  </strong>
              </h5>
              <p className="text-justify">
                All aboard this morning for one of the world’s most scenic train
                journeys!  The low, tropical hills around Kandy soon give way to
                tea plantations, eucalyptus groves and mist-shrouded waterfalls
                as we climb into Sri Lanka’s highlands.  We’ll get off a couple
                of hours later at Hatton, a bustling, aromatic Tamil tea town.
                 After a tour of Hatton’s spice markets and a traditional Sri
                Lankan lunch, we’ll hop on our bikes for a gorgeous 40 kilometer
                (25 mile) ride to our lodge on the shores of Maussakelle
                Reservoir.  Early to bed today as we’ll wake up before dawn
                tomorrow to ascend Adam’s Peak for sunrise.
              </p>
            </Col>
          </Row>
          {/* Day 13 */}
          <Row>
            <Col className="text-left">
              <h5>
                <strong>13.  Ascent of Adam’s Peak </strong>
              </h5>
              <p className="text-justify">
                Adam’s Peak is Sri Lanka’s holiest mountain, and it is the
                aspiration of nearly every Sri Lankan to climb it at least once
                in their lifetime.  We will also enjoy this unique privilege as
                we set out at 2am for a lamp-lit ascent to the summit.  At
                sunrise, the shadow of Adam’s Peak creates a perfect triangle
                over the surrounding hills, a truly spectacular site.  If you
                prefer to ascend in daylight, that is also an option, as our
                afternoon is free today.
              </p>
            </Col>
          </Row>
          {/* Day 14 */}
          <Row>
            <Col className="text-left">
              <h5>
                <strong>14.  Highlands Train to Haputale    </strong>
              </h5>
              <p className="text-justify">
                 After a free morning by the water amongst the hills and water,
                we’ll board the highlands train again for a stunning three-hour
                journey to Haputale, widely considered to be the most
                scenically-located town in Sri Lanka.  Our afternoon is free for
                walking around town with its stunning scenery, or relaxing for
                our upcoming adventures.
              </p>
            </Col>
          </Row>
          {/* Day 15 */}
          <Row>
            <Col className="text-left">
              <h5>
                <strong>15. Hiking at Lipton’s Seat</strong>
              </h5>
              <p className="text-justify">
                 the town of Haputale straddles a ridge at 1800 meters (5,940
                ft) above sea level, with Sri Lanka’s highlands on one side and
                the coastal lowlands on the other.  It is the most stunningly
                situated town in Sri Lanka.  Today we’ll ascend by tuktuk
                (motorized three-wheeler) through a centuries-old tea plantation
                to Lipton’s Seat (yes, that Lipton) for a truly amazing sunrise
                view.  Afterwards, we’ll hike down through the tea plantation
                and then tour Lipton’s old factory with its fascinating antique
                machinery to learn how tea leaves go from bush to cup.  Our
                afternoon is free to relax and enjoy the spectacular view from
                our hotel, or take more walks in the surrounding countryside.
              </p>
            </Col>
          </Row>
          {/* Day 16 */}
          <Row>
            <Col className="text-left">
              <h5>
                <strong>
                  16.  Hike down Devil’s Staircase, Downhill Biking to the
                  Coastal Lowlands
                </strong>
              </h5>
              <p className="text-justify">
                 Today is perhaps the most adrenaline-filled day of our trip as
                we descend almost 1800 meters (5,940 ft) on foot and by bike,
                passing several waterfalls, including Sri Lanka’s highest, on
                the way to the south coast.  With the coastal hills and elephant
                refuge of Udawalawe far below, we’ll hike past eucalyptus
                groves, tea plantations, small villages and smiling, waving kids
                before arriving at the country’s highest falls, Bambarakanda,
                about four hours later.  After visiting the falls, we’ll head by
                private AC transport to our beach spot on the south coast.
              </p>
            </Col>
          </Row>
          {/* Day 17 */}
          <Row>
            <Col className="text-left">
              <h5>
                <strong>
                  17.  Snorkeling with Sea Turtles and Beach Time    
                </strong>
              </h5>
              <p className="text-justify">
                This morning we’ll snorkel among the coral right off the beach,
                spotting sea turtles, lots of colorful fish and even rays if
                we’re lucky.  The afternoon is free to hang out on the beach or
                visit a nearby, off-the-beaten-path site like the local snake
                farm or the nearby lighthouse and Buddhist monastery.
              </p>
            </Col>
          </Row>
          {/* Day 18 */}
          <Row>
            <Col className="text-left">
              <h5>
                <strong>
                  18. Koggala Lagoon Kayaking and Surfing or Beach time  
                </strong>
              </h5>
              <p className="text-justify">
                Koggala Lagoon is a fascinating body of water, with plenty of
                wildlife, eight islands, spice gardens and a 13th century
                Buddhist temple.  Most visitors come by motorboat, but we will
                have a peaceful morning paddle among the mangroves in our
                kayaks.  Our afternoon is free for beach time, a local site or
                even a surfing lesson!
              </p>
            </Col>
          </Row>
          {/* Day 19 */}
          <Row>
            <Col className="text-left">
              <h5>
                <strong>19.  Galle Walking Tour, Backroads Cycling   </strong>
              </h5>
              <p className="text-justify">
                Today we soak up some culture with a walking tour of the
                colonial fort city of Galle.  Another UNESCO World Heritage
                Site, Galle has a very long history, and could even have been
                the Biblical city of Tarsus.  What remains today is a massive
                Portuguese fort enclosing a beautiful neighborhood of old
                colonial buildings, churches and museums. After lunch we hop on
                the bikes for a relaxing back-country jaunt through rice
                paddies, cinnamon groves and coconut plantations.  We’ll see how
                cinnamon is processed, as well as palm toddy (seasonal), a local
                lightly-alcoholic brew.  Evening transfer to Negombo Beach for
                tomorrow’s departing flights.
              </p>
            </Col>
          </Row>
          {/* Day 20 */}
          <Row>
            <Col className="text-left">
              <h5>
                <strong>20.  Departure Day</strong>
              </h5>
              <p className="text-justify">
                Today we must say goodbye, but if your flight leaves in the
                afternoon, we can have one final paddle among the fishing boats
                and small islands of Negombo Lagoon.  A free transfer to the
                airport is of course included.  Thanks for traveling with us,
                and we hope to see you on another adventure very soon!
              </p>
            </Col>
          </Row>
          {/* Booking button  */}
          <Row id="booking" className="mx-1">
            <Col>
              <Row className="mt-3 mb-3">
                <Col>
                  <Button
                    onClick={this.showForm}
                    className="btn btn-secondary inquirebtn-bookingform"
                  >
                    Inquire
                  </Button>
                  
                </Col>
              </Row>
            </Col>
          </Row>
          {/* Booking button  */}
          {/* Modal Popup */}
          <Row>
            <Col>
              {this.state.showBookingForm && (
                <Modal
                  size="lg"
                  aria-labelledby="contained-modal-title-vcenter"
                  centered
                  show={this.state.showBookingForm}
                  onHide={this.hideForm}
                  className="bookyourtrip-tours2"
                >
                  <Modal.Header closeButton>
                    <Modal.Title>Please fill the details below.</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Container>
                      <Row className="show-grid">
                        <Col xs={12}>
                          <TourBookingForm
                            tourname="Cover All Sri Lanka Tour"
                            no_of_days="20"
                            no_of_nights="19"
                          ></TourBookingForm>
                        </Col>
                      </Row>
                    </Container>
                  </Modal.Body>
                </Modal>
              )}
            </Col>
          </Row>
          {/* Modal Popup */}
        </Container>
      </React.Fragment>
    );
  }
}

export default CoverSriLankaTour;

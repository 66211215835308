import React, { Component } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import { Animated } from "react-animated-css";

class Destinations extends Component {
  state = {
    isHovered: false
  };

  constructor() {
    super();
    document.getElementById("body").className = "bg-img bg-margin";
  }

  render() {
    return (
      <React.Fragment>
        <Container>
          <Row>
            <Col>
              <p>&nbsp;</p>
            </Col>
          </Row>
          <Row>
            <Col>
              <h3
                data-aos="fade-up-right"
                data-aos-offset="0"
                data-aos-easing="ease-in-out"
                data-aos-duration="1000"
                data-aos-anchor-placement="top-bottom"
              >
                ATTRACTIVE DESTINATIONS IN SRI LANKA
              </h3>
            </Col>
          </Row>
          <Row>
            <Col>
              <p>&nbsp;</p>
            </Col>
          </Row>
          {/* start grid */}

          <Row className="mx-3">
            <Col md={6} className="my-3">
              <Card className="box-shadow">
                <Card.Footer>
                  <Card.Title className="text-white">Negombo</Card.Title>
                </Card.Footer>
                <Card.Img
                  variant="top"
                  src="./img/destinations/negombo-sri-lanka.jpg"
                />
                <Card.Body className="detination-cardbody-height text-justify">
                  <Card.Text>
                    Negombo is a modest beach town located just 10km from
                    Bandaranaike International Airport. With a stash of decent
                    hotels and restaurants to suit all pockets, a friendly local
                    community, an interesting old quarter and a reasonable
                    (though somewhat polluted) beach.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col md={6} className="my-3">
              <Card id="cardcolombo" className="box-shadow">
                <Card.Footer>
                  <Card.Title className="text-white">Colombo</Card.Title>
                </Card.Footer>
                <Card.Img variant="top" src="./img/destinations/colombo.jpg" />
                <Card.Body className="detination-cardbody-height text-justify">
                  <Card.Text>
                    Colombo, the capital of Sri Lanka, has a long history as a
                    port on ancient east-west trade routes, ruled successively
                    by the Portuguese, Dutch and British. That heritage is
                    reflected in its its architecture, mixing colonial buildings
                    with high-rises and shopping malls. The imposing Colombo
                    National Museum, dedicated to Sri Lankan history, borders
                    sprawling Viharamahadevi Park and its giant Buddha.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row className="mx-3">
            <Col md={6} className="my-3">
              <Card className="box-shadow">
                <Card.Footer>
                  <Card.Title className="text-white">Kandy</Card.Title>
                </Card.Footer>
                <Card.Img
                  variant="top"
                  src="./img/destinations/temple-of-the-sacred-tooth-relic-kandy.jpg"
                />
                <Card.Body className="detination-cardbody-height text-justify">
                  <Card.Text>
                    Kandy is a large city in central Sri Lanka. It's set on a
                    plateau surrounded by mountains, which are home to tea
                    plantations and biodiverse rainforest. The city's heart is
                    scenic Kandy Lake (Bogambara Lake), which is popular for
                    strolling. Kandy is famed for sacred Buddhist sites,
                    including the Temple of the Tooth (Sri Dalada Maligawa)
                    shrine, celebrated with the grand Esala Perahera annual
                    procession.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col md={6} className="my-3">
              <Card className="box-shadow">
                <Card.Footer>
                  <Card.Title className="text-white">Nuwara Eliya</Card.Title>
                </Card.Footer>
                <Card.Img
                  variant="top"
                  src="./img/destinations/nuwara-eliya.jpg"
                />
                <Card.Body className="detination-cardbody-height text-justify">
                  <Card.Text>
                    Nuwara Eliya is a city in the tea country hills of central
                    Sri Lanka. The naturally landscaped Hakgala Botanical
                    Gardens displays roses and tree ferns, and shelters monkeys
                    and blue magpies. Nearby Seetha Amman Temple, a colorful
                    Hindu shrine, is decorated with religious figures. Densely
                    forested Galway's Land National Park is a sanctuary for
                    endemic and migratory bird species, including bulbuls and
                    flycatchers.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row className="mx-3">
            <Col md={6} className="my-3">
              <Card className="box-shadow">
                <Card.Footer>
                  <Card.Title className="text-white">Sigiriya</Card.Title>
                </Card.Footer>
                <Card.Img variant="top" src="./img/destinations/sigiriya.jpg" />
                <Card.Body className="detination-cardbody-height text-justify">
                  <Card.Text>
                    Rising dramatically from the central plains, the enigmatic
                    rocky outcrop of Sigiriya is perhaps Sri Lanka's single most
                    dramatic sight. Near-vertical walls soar to a flat-topped
                    summit that contains the ruins of an ancient civilisation,
                    thought to be once the epicentre of the short-lived kingdom
                    of Kassapa, and there are spellbinding vistas across
                    mist-wrapped forests in the early morning.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col md={6} className="my-3">
              <Card className="box-shadow">
                <Card.Footer>
                  <Card.Title className="text-white">Polonnaruwa</Card.Title>
                </Card.Footer>
                <Card.Img
                  variant="top"
                  src="./img/destinations/polonnaruwa.jpg"
                />
                <Card.Body className="detination-cardbody-height text-justify">
                  <Card.Text>
                    Poḷonnaruwa is the main town of Polonnaruwa District in
                    North Central Province, Sri Lanka. Kaduruwela area is the
                    Polonnaruwa New Town and the other part of Polonnaruwa
                    remains as the royal ancient city of the Kingdom of
                    Polonnaruwa.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row className="mx-3">
            <Col md={6} className="my-3">
              <Card className="box-shadow">
                <Card.Footer>
                  <Card.Title className="text-white">Anuradhapura</Card.Title>
                </Card.Footer>
                <Card.Img
                  variant="top"
                  src="./img/destinations/anuradhapura.jpg"
                />
                <Card.Body className="detination-cardbody-height text-justify">
                  <Card.Text>
                    Anuradhapura is a major city in Sri Lanka. It is the capital
                    city of North Central Province, Sri Lanka and the capital of
                    Anuradhapura District. Anuradhapura is one of the ancient
                    capitals of Sri Lanka, famous for its well-preserved ruins
                    of an ancient Sinhala civilization.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col md={6} className="my-3">
              <Card className="box-shadow">
                <Card.Footer>
                  <Card.Title className="text-white">Ella</Card.Title>
                </Card.Footer>
                <Card.Img
                  variant="top"
                  src="./img/destinations/Ella-Sri-Lanka.webp"
                />
                <Card.Body className="detination-cardbody-height text-justify">
                  <Card.Text>
                    Ella in Sri Lanka is a small laid-back town surrounded by
                    the beautiful greens of tea. There are a lot of things to do
                    in Ella. Take one of the most beautiful train rides from
                    Nuwara Eliya to Ella and fall in love with the surroundings.
                    You will probably end up staying longer than you actually
                    planned to. Ella has a comfortable climate where the nights
                    are cool and the daytime around 28 degrees.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row className="mx-3">
            <Col md={6} className="my-3">
              <Card className="box-shadow">
                <Card.Footer>
                  <Card.Title className="text-white">Galle</Card.Title>
                </Card.Footer>
                <Card.Img
                  variant="top"
                  src="./img/destinations/Galle-Fort-Sri-Lanka.jpg"
                />
                <Card.Body className="detination-cardbody-height text-justify">
                  <Card.Text>
                    Galle is a city on the southwest coast of Sri Lanka. It’s
                    known for Galle Fort, the fortified old city founded by
                    Portuguese colonists in the 16th century. Stone sea walls,
                    expanded by the Dutch, encircle car-free streets with
                    architecture reflecting Portuguese, Dutch and British rule.
                    Notable buildings include the 18th-century Dutch Reformed
                    Church. Galle Lighthouse stands on the fort’s southeast tip.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col md={6} className="my-3">
              <Card className="box-shadow">
                <Card.Footer>
                  <Card.Title className="text-white">Bentota</Card.Title>
                </Card.Footer>
                <Card.Img
                  variant="top"
                  src="./img/destinations/bentota-srilanka.jpg"
                />
                <Card.Body className="detination-cardbody-height text-justify">
                  <Card.Text>
                    Bentota is a resort town on Sri Lanka’s southwest coast. Its
                    long Bentota Beach stretches north, where it becomes a sandy
                    strip known as Paradise Island, parallel to Bentota Lagoon.
                    Coral-rich dive sites include Canoe Rock. On Bentota River,
                    centuries-old Galapota Temple has a large Buddha statue.
                    Southeast is Lunuganga, the estate and gardens of architect
                    Geoffrey Bawa. Northeast is his brother Bevis's Brief
                    Garden.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>

          {/* end grid */}
        </Container>
      </React.Fragment>
    );
  }
}

export default Destinations;

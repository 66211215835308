import React, { Component } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ColomboSlider from "./colomboslider";
import KandySlider from "./kandyslider";
import NEliyaSlider from "./neliyaslider";
import BentotaSlider from "./bentotaslider";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { Link } from "react-router-dom";
import TourBookingForm from "./TourBookingForm";
import { Button } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";

class SpiritualTour extends Component {
  state = {
    showBookingForm: false
  };

  constructor()
  {
    super();
    document.getElementById("body").style.marginTop = "150px";
  }
  
  showForm = () =>
    this.setState({
      showBookingForm: true
    });

  hideForm = () =>
    this.setState({
      showBookingForm: false
    });

  render() {
    return (
      <React.Fragment>
        <Container className="p-4">
          <Row>
            <Col>
              <Breadcrumb>
                <Breadcrumb.Item>
                  <Link to="/" className=" active  text-left">
                    Home
                  </Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                  <Link to="/mostfamoustours" className=" active  text-left">
                    Most Famous Tours
                  </Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item active>
                  Hindu Tour 04 Nights 05 Days
                </Breadcrumb.Item>
              </Breadcrumb>
            </Col>
          </Row>
          <Row>
            <Col>
              <h3>Hindu Tour 04 Nights 05 Days</h3>
            </Col>
          </Row>
          <Row>
            <Col>
              <p>&nbsp;</p>
            </Col>
          </Row>
          <Row>
            <Col>
              <p>&nbsp;</p>
            </Col>
          </Row>
          {/* Day 01 */}
          <Row>
            <Col className="text-left">
              <h5>
                {" "}
                <strong>
                  Day 01: Arrival / Negombo (Expecting Morning arrival){" "}
                </strong>
              </h5>
              <ul>
                <li>After Arrival Transfer to Chilaw</li>
                <li>Visit Sri Munneswaram Devasthanam and Manavari Kovil</li>
              </ul>
              <p className="text-justify">
                Sri Lanka dubbed as Shiva Bhumi (the land of God Shiva) in
                ancient Indian epics and literature is blessed with the five
                hallowed shrines of Shiva (pancheswarams) and three of them,
                according to mythology, were consecrated by Sri Rama, the hero
                of the epic Ramayana who ruled in Ayodhya and is believed to be
                the incarnation of Hindu God Vishnu. According to mythology, he
                was returning to Ayodhya in his airship ‘pushpak Viman’ with his
                consort Sita, younger brother Laksman, Lankan King Ravana’s
                brother Vibheeshana and his contingent after defeating Ravana in
                war when ‘ brammagaththi’ , the evil-spirit for the sins he
                committed in the war, had him in its grip.
              </p>
              <ul>
                <li>Overnight stay in Negombo</li>
              </ul>
            </Col>
          </Row>
          <Row>
            <Col>
              <ColomboSlider></ColomboSlider>
            </Col>
          </Row>
          <Row>
            <Col>
              <p>&nbsp;</p>
            </Col>
          </Row>
          {/* day 02 */}
          <Row>
            <Col className="text-left">
              <h5>
                {" "}
                <strong>Day 02: Negombo/Kandy</strong>
              </h5>
              <ul>
                <li>After Breakfast Transfer to Kandy</li>
                <li>En Route visit Pinnawala elephant orphanage</li>
              </ul>
              <p>
                <strong>PINNAWALA</strong>
                <p className="text-justify">
                  App. 55 miles from Colombo, off the Colombo - Kandy road is
                  Pinnawela, where an orphanage was started in 1975 in an area
                  of 25 acres to house the abandoned and the wounded elephants.
                  The orphanage has grown to the world largest herd of captive
                  elephants today that cares for nearly 90 orphaned elephants,
                  including baby elephants brought from various parts, as well
                  as more than 60 babies born. The unique experience is, have
                  the opportunity not only observe the entire herd bathing in
                  the river but also to feed them with fruits and milk.
                </p>
              </p>
              <ul>
                <li>Visit Temple of the tooth</li>
                <li>Visit Cultural Dance Show </li>
              </ul>
              <p>
                <strong>TEMPLE OF THE SACRED TOOTH RELIC</strong>
                <p className="text-justify">
                  Ever since 4th Century A.D, when the Buddha's Tooth was
                  brought to Sri Lanka hidden from sacrilegious hands in an
                  Orissan princess' hair, the Relic has grown in reputation and
                  holiness in Sri Lanka and throughout the Buddhist world. Sri
                  Dalada Maligawa “Temple of the Sacred Tooth Relic” which house
                  the most sacred Tooth Relic of Lord Buddha. Historical
                  procession called “Esala Perahera” is held annually during the
                  month of July to pay homage to the Sacred Tooth Relic of Lord
                  Buddha.
                </p>
              </p>
              <ul>
                <li>Overnight stay in Kandy</li>
              </ul>
            </Col>
          </Row>
          <Row>
            <Col>
              <KandySlider></KandySlider>
            </Col>
          </Row>
          <Row>
            <Col>
              <p>&nbsp;</p>
            </Col>
          </Row>
          {/* day 03 */}
          <Row>
            <Col className="text-left">
              <h5>
                {" "}
                <strong>Day 03: Kandy/Nuwara Eliya</strong>
              </h5>

              <p>
                <strong>ROYAL BOTANICAL GARDEN, PERADENIYA</strong>
                <p className="text-justify">
                  As Sri Lanka’s largest garden an elegant and spacious
                  147-acres (60-hectares) plenty of time is needed to stroll
                  Peradeniya’s imposing Avenue of Royal Palms. There are some
                  4,000 different species of plants at Peradeniya Gardens. The
                  10,000 or so trees, which are the stars, are mature, lofty
                  giants, many of them tropical timber trees. Highlights of the
                  collection include the Giant Bamboo of Burma, capable of
                  growing to 40 meters’ height (130 feet) with a 25-centimetre
                  (10-inch) stem diameter. And it can grow by a rapid 30
                  centimeters a day (12 inches). Sensational is the century old
                  giant Javan fig tree, its tentacle like roots spread across
                  the enormous area of about 1,800 square meters (19,375 square
                  feet) a massive central trunk beneath the tree’s vast canopy
                  ‘umbrella’.
                </p>
              </p>
              <ul>
                <li>Visit Ramboda water fall</li>
                <li>Visit Hanuman Temple </li>
              </ul>
              <p className="text-justify">
                According to the ancient stories, Ramboda is a area, where the
                forces of Rama's gather to fight against King Ravana. This
                temple was built by the Chinmaya mission of Sri Lanka and
                dedicated to Hanuman. This temple houses for 16 feel height
                statue of Hanuman.
              </p>
              <ul>
                <li>Explore the Tea Plantation</li>
              </ul>
              <p>
                <strong>NUWARAELIYA</strong>
                <p className="text-justify">
                  The ‘Little England’ of Sri Lanka, is set against beautiful
                  backdrops of Mountains, Valleys, Waterfalls and Tea
                  Plantations. Samuel Baker, the discoverer of Lake Albert and
                  the explorer o f the Nile in 1846, founded the city. It is
                  supposed to be one of the coldest places on the island, but is
                  really just like an England spring day although the
                  temperature does drop at night. Many of the buildings retain
                  features from the colonial period such as the Queen’s Cottage,
                  Grand Hotel, Hill club, Post office ect.. Town attractions
                  include the golf course, trout streams, Victoria park, and
                  boating on Lake Gregory.
                </p>
              </p>
              <ul>
                <li>Overnight stay in Nuwara Eliya</li>
              </ul>
            </Col>
          </Row>
          <Row>
            <Col>
              <NEliyaSlider></NEliyaSlider>
            </Col>
          </Row>
          <Row>
            <Col>
              <p>&nbsp;</p>
            </Col>
          </Row>
          {/* day 04 */}
          <Row>
            <Col className="text-left">
              <h5>
                {" "}
                <strong>Day 04: Nuwara Eliya/Bentota</strong>
              </h5>
              <ul>
                <li>Breakfast at the hotel </li>
                <li>Visit Seetha Amman temple</li>
                <li>Kitulgala water rafting</li>
              </ul>
              <p>
                <strong>KITULGALA</strong>
                <p className="text-justify">
                  Kitulgala is a small town in the west of Sri Lanka. It is in
                  the wet zone rainforest, which gets two monsoons each year,
                  and is one of the wettest places in the country. Nevertheless,
                  it comes alive in the first three months of the year,
                  especially in February, The driest month. The Academy
                  Award-winning "The Bridge on the River Kwai" was filmed on the
                  Kelani near Kitulgala, although nothing remains now except the
                  concrete foundations for the bridge (and, supposedly, the
                  submerged train cars that plunged into the river in the
                  climactic scene). Kitulgala is also a base for white-water
                  rafting, which starts a few kilometers upstream.
                </p>
                <p className="text-justify">
                  The agriculture around Kitulgala is typical of the hilly wet
                  zone. The Sago Palm, Caryotaurens, which is called Kitul in
                  Sri Lanka, gives rise to the town's name. Its sap is
                  concentrated into delicious syrup, not dissimilar to maple
                  syrup, and crystallized as jiggery. It is also fermented to
                  make toddy. The pith is used to make sago, and the fibers to
                  make rope
                </p>
              </p>
              <ul>
                <li>Transfer to Bentota</li>
              </ul>
              <p>
                <strong>BENTOTA</strong>
                <p className="text-justify">
                  Bentota is a coastal town in Sri Lanka, located in the Galle
                  District of the Southern Province, governed by an Urban
                  Council. It is approximately 65 kilometers (40 mi) south of
                  Colombo and 56 kilometers (35 mi) north of Galle. Bentota is a
                  tourist attraction, with a local airport (Bentota River
                  Airport) and a handful and excellent hotels. It is a
                  destination for water sports. Bentota also delivers an ancient
                  art of healing called Ayurveda. Bentota is famous for its
                  toddy production, an alcoholic beverage made out of coconut
                  nectar.
                </p>
              </p>
              <ul>
                <li>Overnight stay in Bentota</li>
              </ul>
            </Col>
          </Row>
          <Row>
            <Col>
              <BentotaSlider></BentotaSlider>
            </Col>
          </Row>
          <Row>
            <Col>
              <p>&nbsp;</p>
            </Col>
          </Row>
          {/* day 05 */}
          <Row>
            <Col className="text-left">
              <h5>
                {" "}
                <strong>Day 05: Bentota/Colombo/Airport</strong>
              </h5>
              <ul>
                <li>Breakfast at the hotel </li>
                <li>Boat Ride in Madu River</li>
              </ul>
              <p className="text-justify">
                <strong>Madu ganga </strong>
                is situated in Balapitiya, along the country’s southern coast,
                within the Galle District of the southern Province of Sri Lanka.
                It opens to the Indian Ocean Madu lagoon is a very wide and a
                beautiful lagoon in sri lanka. It is in the wet zone of sri
                lanka with a high bio-diversity. Madu River has been proclaimed
                a Ramsa wetland in the world in 2004. A word from the captain’s
                boathouse Gangabada Asiriya in Balapitiya in the coastal area of
                sri lanka. This is a very famous and popular among the lovers of
                nature in the world.sWe are very proud to say that we are the
                best suppliers of providing the best facilities and protection
                for all the guests who go round the madu lagoon to see the
                beauty of the nature.
              </p>
              <ul>
                <li>Explore the Colombo City</li>
              </ul>
              <p className="text-justify">
                <strong>Colombo -</strong> A drives through the city taking you
                to the busy commercial areas as well as the tree-lined
                residential, Cinnamon Gardens. Visit Fort, the former British
                administrative center and military garrison, Sea Street - the
                Goldsmith’s quarters in the heart of Pettah, the Bazaar area,
                where is also a Hindu Temple with elaborate stone carvings and
                the Dutch Church of Wolfendhal dating back to 1749. Also visit
                the BMICH, see the replica of Avukana Buddha and Independence
                Square.
              </p>
              <ul>
                <li>Drop to the Airport</li>
              </ul>
            </Col>
          </Row>
          <Row>
            <Col>
              <BentotaSlider></BentotaSlider>
            </Col>
          </Row>
          <Row>
            <Col>
              <p>&nbsp;</p>
            </Col>
          </Row>

          {/* terms and conditions */}
          <Row>
            <Col className="text-left">
              <h5>
                <strong>Terms and Conditions</strong>
              </h5>
              <p className="text-justify">
                <strong>
                  ANY EXPENSES CAUSED BY REASONS BEYOND OUR CONTROL SUCH AS
                  TRAIN – FLIGHT DELAYS / CANCELLATION RESCHEDULING, ETC AND ANY
                  OTHER SITUATIONS RELATED TO ROAD BLOCKS, ACCIDENTS, MEDICAL
                  EVACUATION AND NATURAL CALAMITIES.
                </strong>
              </p>
              <p className="text-justify">
                Rooms will be confirmed on first come first serve basis & if in
                case if the said hotels are completely booked to the capacity,
                similar hotels will be provided in the same vicinity or nearest
                location. Standard check-in time and check-out time at the
                hotels 1200 Hrs – 1200 Hrs
              </p>
              <p className="text-justify">
                Travel time mentioned is approx time and is subject to change as
                per traffic and road conditions.
              </p>
            </Col>
          </Row>
          {/* Booking button  */}
          <Row id="booking" className="mx-1">
            <Col>
              <Row className="mt-3 mb-3">
                <Col>
                  <Button onClick={this.showForm} className="btn btn-secondary inquirebtn-bookingform">
                     Inquire
                  </Button>
                  {/* <TourBookingForm tourname="Hindu Tour 04 Nights 05 Days" no_of_days="05" no_of_nights="04"></TourBookingForm> */}
                </Col>
              </Row>
            </Col>
          </Row>
          {/* Booking button  */}
          {/* Modal Popup */}
          <Row>
            <Col>
              {this.state.showBookingForm && (
                <Modal  size="lg"
                  aria-labelledby="contained-modal-title-vcenter"
                  centered
                  show={this.state.showBookingForm}
                  onHide={this.hideForm}
                  className="bookyourtrip-tours2"
                  
                >
                  <Modal.Header closeButton>
                    <Modal.Title>Please fill the details below.</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Container>
                      <Row className="show-grid">
                        <Col xs={12}>
                          <TourBookingForm
                            tourname="Hindu Tour 04 Nights 05 Days"
                            no_of_days="05"
                            no_of_nights="04"
                          ></TourBookingForm>
                        </Col>
                      </Row>
                    </Container>
                  </Modal.Body>
                </Modal>
              )}
            </Col>
          </Row>
          {/* Modal Popup */}
        </Container>
      </React.Fragment>
    );
  }
}

export default SpiritualTour;

import React, { Component } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

class ActivitiesDetails extends Component {
  state = {};

  constructor()
  {
    super();
    document.getElementById("body").className = "bg-img bg-margin";
  }

  render() {
    return (
      <React.Fragment>
        <Container className="p-5">
        <Row>
            <Col>
              <p>&nbsp;</p>
            </Col>
          </Row>
          <Row>
            <Col>
              <h3 data-aos="fade-up-right"
              data-aos-offset="0"
              data-aos-easing="ease-in-out"
              data-aos-duration="1000"
              data-aos-anchor-placement="top-bottom">ACTIVITIES IN SRI LANKA</h3>
            </Col>
          </Row>
          <Row>
            <Col>
              <p>&nbsp;</p>
            </Col>
          </Row>
          {/* Kayaking in Sri Lanka */}
          <Row data-aos="fade-up-right"
                  data-aos-easing="ease-in-out-quart"
                  data-aos-offset="-10"
                  data-aos-duration="1500"
                  data-aos-anchor-placement="top-bottom">
            <Col className="text-left">
              <Row>
                <h5>
                  <strong>Kayaking in Sri Lanka</strong>
                </h5>
              </Row>
              <Row className="text-justify">
                <p>
                  Kayaking is the use of a kayak for moving across water. It is
                  distinguished from canoeing by the sitting position of the
                  paddler and the number of blades on the paddle. A kayak is a
                  low-to-the-water, canoe-like boat in which the paddler sits
                  facing forward, legs in front, using a double-bladed paddle to
                  pull front-to-back on one side and then the other in
                  rotation Most kayaks have closed decks,
                  although sit-on-top and inflatable kayaks are growing in
                  popularity as well.
                </p>
              </Row>
              <Row>
                <Col className="text-center align-top">
                  <img
                    src="../img/Kayaking__Canoeing_srilanka.jpg"
                    className="img-fluid activities-kayaking-img box-shadow"
                    alt="Responsive image"
                  ></img>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <p>&nbsp;</p>
          </Row>
          {/* Kitulgala White Water Rafting */}
          <Row data-aos="fade-up-left"
                  data-aos-easing="ease-in-out-quart"
                  data-aos-offset="-10"
                  data-aos-duration="1500"
                  data-aos-anchor-placement="top-bottom">
            <Col className="text-left">
              <Row>
                <h5>
                  {" "}
                  <strong>Kitulgala Water Rafting</strong>
                </h5>
              </Row>
              <Row className="text-justify">
                <p>
                  Experience White Water Rafting in the picturesque Kelani
                  River, covering 5 major rapids and 4 minor rapids. This
                  activity is for anyone above the age of 10 years with safety
                  gear, modern rafts, and a comprehensive safety briefing will
                  be given by our white water rafting instructors beforehand.
                  The distance covered is around 5 KMs. The river and its
                  surrounding will make you feel enchanted, with a memorable
                  Experience during your White Water Rafting Tour in Kitulagala.
                </p>
              </Row>
              <Row>
                <Col className="text-center align-top">
                  <img
                    src="../img/water-rafting-kitulgala.jpg"
                    className="img-fluid activities-kayaking-img box-shadow"
                    alt="Responsive image"
                  ></img>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <p>&nbsp;</p>
          </Row>
          {/* Knuckles Mountain Trekking */}
          <Row data-aos="fade-up-right"
                  data-aos-easing="ease-in-out-quart"
                  data-aos-offset="-10"
                  data-aos-duration="1500"
                  data-aos-anchor-placement="top-bottom">
            <Col className="text-left">
              <Row>
                <h5>
                  {" "}
                  <strong>Knuckles Mountain Trekking</strong>
                </h5>
              </Row>
              <Row className="text-justify">
                <p>
                  In the central and southern parts of Sri Lanka there are
                  several peaks that are highly ecological and rich in bio
                  diversity. These mountains are preserved as forests and are
                  the starting points of many rivers.
                </p>
                <p>
                  Knuckles is one the most important bio diversity environments
                  with range of mountains and a great water and wild life
                  resource. Loved by travellers for its beauty Knuckles
                  Mountains has become a hot spot in eco-tourism in Sri Lanka.
                </p>
              </Row>
              <Row>
                <Col className="text-center align-top">
                  <img
                    src="../img/Knuckles_Mountain_Trekking.jpg"
                    className="img-fluid activities-kayaking-img box-shadow"
                    alt="Responsive image"
                  ></img>
                </Col>
              </Row>
            </Col>
          </Row>
          {/* Hot air Balloon Dambulla */}
          <Row>
            <p>&nbsp;</p>
          </Row>
          <Row data-aos="fade-up-left"
                  data-aos-anchor-placement="top-bottom"
                  data-aos-offset="-10"
                  data-aos-easing="ease-in-out-quart"
                  data-aos-duration="1500">
            <Col className="text-left">
              <Row>
                <h5>
                  {" "}
                  <strong>Hot Air Balloon Dambulla</strong>
                </h5>
              </Row>
              <Row className="text-justify">
                <p>
                  If you are lucky enough to visit the beautiful island of Sri
                  Lanka then you should take the opportunity to see the wondrous
                  sights from the air starting from Kandalama, Dambulla in the
                  Cultural Triangle.
                </p>
                <p>
                  Flying over the jungle you may see elephants roaming freely,
                  variety of birds, wild life, water buffaloes standing in the
                  paddy fields and monkeys swinging from the trees.
                </p>
              </Row>
              <Row>
                <Col className="text-center align-top">
                  <img
                    src="../img/hot-air-balloon-Sigiriya-sri-lanka.jpg"
                    className="img-fluid activities-kayaking-img box-shadow"
                    alt="Responsive image"
                  ></img>
                </Col>
              </Row>
            </Col>
          </Row>
          {/* Snorkeling and Diving */}
          <Row>
            <p>&nbsp;</p>
          </Row>
          <Row data-aos="fade-up-right"
                  data-aos-anchor-placement="top-bottom"
                  data-aos-offset="-10"
                  data-aos-easing="ease-in-out-quart"
                  data-aos-duration="1500">
            <Col className="text-left">
              <Row>
                <h5>
                  {" "}
                  <strong>Snorkeling and Diving</strong>
                </h5>
              </Row>
              <Row className="text-justify">
                <p>
                  Whether you’ve never done it before, or if you’re an expert,
                  this is the wonderful sea activity for everyone. Swim in the
                  warm Indian Ocean, and see tropical fish, the coral reefs and
                  try and maneuver through the seaweed that floats underwater.
                </p>
              </Row>
              <Row>
                <Col className="text-center align-top">
                  <img
                    src="../img/snorkeling_diving.jpg"
                    className="img-fluid activities-kayaking-img box-shadow"
                    alt="Responsive image"
                  ></img>
                </Col>
              </Row>
            </Col>
          </Row>
          {/* Banana boat & Tube rides  */}
          <Row>
            <p>&nbsp;</p>
          </Row>
          <Row data-aos="fade-up-left"
                  data-aos-anchor-placement="top-bottom"
                  data-aos-offset="-10"
                  data-aos-easing="ease-in-out-quart"
                  data-aos-duration="1500">
            <Col className="text-left">
              <Row>
                <h5>
                  {" "}
                  <strong>Banana Boat & Tube Rides </strong>
                </h5>
              </Row>
              <Row className="text-justify">
                <p>
                  Banana tube is like its name says, shaped like a banana and is
                  attached to a ski boat by roap. The ride will guarantee that
                  huge waves will come thrashing over your heads. While being
                  suitable for any age, you can select from single rings, and
                  sofa rides as well in Bentota.
                </p>
              </Row>
              <Row>
                <Col className="text-center align-top">
                  <img
                    src="../img/Banana-Boat-ride-srilanka.jpg"
                    className="img-fluid activities-kayaking-img box-shadow"
                    alt="Responsive image"
                  ></img>
                </Col>
              </Row>
            </Col>
          </Row>
          {/* Wind Surfing */}
          <Row>
            <p>&nbsp;</p>
          </Row>
          <Row data-aos="fade-up-right"
                  data-aos-anchor-placement="top-bottom"
                  data-aos-offset="-10"
                  data-aos-easing="ease-in-out-quart"
                  data-aos-duration="1500">
            <Col className="text-left">
              <Row>
                <h5>
                  <strong>Wind Surfing</strong>
                </h5>
              </Row>
              <Row className="text-justify">
                <p>
                  Sri Lanka provides the perfect atmosphere all year round, for
                  wind surfing. You can choose to hire equipment or bring your
                  own gear.
                </p>
              </Row>
              <Row>
                <Col className="text-center align-top">
                  <img
                    src="../img/windsurfing-srilanka.jpg"
                    className="img-fluid activities-kayaking-img box-shadow"
                    alt="Responsive image"
                  ></img>
                </Col>
              </Row>
            </Col>
          </Row>
          {/* Jet Ski */}
          <Row>
            <p>&nbsp;</p>
          </Row>
          <Row data-aos="fade-up-left"
                  data-aos-anchor-placement="top-bottom"
                  data-aos-offset="-10"
                  data-aos-easing="ease-in-out-quart"
                  data-aos-duration="1500">
            <Col className="text-left">
              <Row>
                <h5>
                  <strong>Jet Ski</strong>
                </h5>
              </Row>
              <Row className="text-justify">
                <p>
                  Jet Skiing is one of the most fun and adventurous water
                  sporting activities in Bentota. Though its mostly enjoyed by
                  the trill seeker, beginners can chose to be accompanied by an
                  experienced instructor as well. This is a must do with your
                  friends, family or with your loved ones while in Bentota.
                </p>
              </Row>
              <Row>
                <Col className="text-center align-top">
                  <img
                    src="../img/Jetskiing-Sri-Lanka.jpg"
                    className="img-fluid activities-kayaking-img box-shadow"
                    alt="Responsive image"
                  ></img>
                </Col>
              </Row>
            </Col>
          </Row>
          {/* Water Skiing */}
          <Row>
            <p>&nbsp;</p>
          </Row>
          <Row data-aos="fade-up-right"
                  data-aos-anchor-placement="top-bottom"
                  data-aos-offset="-10"
                  data-aos-easing="ease-in-out-quart"
                  data-aos-duration="1500">
            <Col className="text-left">
              <Row>
                <h5>
                  <strong>Water Skiing</strong>
                </h5>
              </Row>
              <Row className="text-justify">
                <p>
                  A great way to enjoy on the beach is water skiing. Something
                  definitely for the adventure lover, even for visitors this is
                  an activity that is fun to watch as well.
                </p>
              </Row>
              <Row>
                <Col className="text-center align-top">
                  <img
                    src="../img/waterski-sri-lanka.jpg"
                    className="img-fluid activities-kayaking-img box-shadow"
                    alt="Responsive image"
                  ></img>
                </Col>
              </Row>
            </Col>
          </Row>
          {/* Whale watching  Mirissa */}
          <Row>
            <p>&nbsp;</p>
          </Row>
          <Row data-aos="fade-up-left"
                  data-aos-anchor-placement="top-bottom"
                  data-aos-offset="-10"
                  data-aos-easing="ease-in-out-quart"
                  data-aos-duration="1500">
            <Col className="text-left">
              <Row>
                <h5>
                  <strong>Whale Watching  Mirissa</strong>
                </h5>
              </Row>
              <Row className="text-justify">
                <p>
                  Whale and dolphin watching in Mirissa is one of the most
                  exciting water activities you can do during your holiday in
                  Sri Lanka. Often spotted blue whales are the biggest animals
                  in the world and Mirissa is the best place for whale and
                  dolphin watching tour in Sri Lanka. Whale watching season in
                  Mirissa is from November to April.
                </p>
              </Row>
              <Row>
                <Col className="text-center align-top">
                  <img
                    src="../img/Whale_Watching_Sri_Lanka.jpg"
                    className="img-fluid activities-kayaking-img box-shadow"
                    alt="Responsive image"
                  ></img>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    );
  }
}

export default ActivitiesDetails;

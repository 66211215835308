import React, { Component } from "react";
import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import { CardColumns } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link } from "react-router-dom";

class AllTours extends Component {
  state = {};

  constructor() {
    super();
    document.getElementById("body").className = "bg-img bg-margin";
  }

  render() {
    return (
      <React.Fragment>
        <Container>
          <Row>
            <Col>
              <p>&nbsp;</p>
            </Col>
          </Row>
          <Row>
            <Col>
              <h3>MOST POPULAR ITINERARIES</h3>
            </Col>
          </Row>
          <Row>
            <Col>
              <p>&nbsp;</p>
            </Col>
          </Row>
          <Row>
            <Col>
              <Card
                data-aos="fade-up-right"
                data-aos-easing="ease-in-out-quart"
                data-aos-offset="-10"
                data-aos-duration="1500"
                data-aos-anchor-placement="top-bottom"
                className="text-left m-2 box-shadow"
              >
                <Card.Header className="mt-2 text-center">
                  <h4 className="my-4">Hindu Tour 04 Nights 05 Days</h4>
                  <Card.Img
                    src="./img//tours/munneswaram-kovil.jpg"
                    alt="Card image"
                  />
                </Card.Header>

                <Card.Body>
                  <Card.Title className="landing-textdropshadow"></Card.Title>
                  <Card.Text className="tourscardtextheight">
                    <ul>
                      <li>
                        VISIT SRI MUNNESWARAM DEVASTHANAM AND MANAVARI KOVIL
                      </li>
                      <li>NEGOMBO/KANDY</li>
                      <li>PINNAWALA</li>
                      <li>TEMPLE OF THE SACRED TOOTH RELIC</li>
                      <li>ROYAL BOTANICAL GARDEN, PERADENIYA</li>
                      <li>NUWARAELIYA</li>
                      <li>KITULGALA</li>
                      <li>BENTOTA</li>
                      <li>Colombo </li>
                    </ul>
                  </Card.Text>
                </Card.Body>
                <Card.Footer>
                  <Link
                    to="/spiritualtour"
                    className="nav-link active py-2 d-block text-white text-center"
                  >
                    More Details
                  </Link>
                </Card.Footer>
              </Card>
            </Col>
            <Col>
              <Card
                data-aos="fade-down-right"
                data-aos-easing="ease-in-out-quart"
                data-aos-offset="-10"
                data-aos-duration="1500"
                data-aos-anchor-placement="top-bottom"
                className="text-left m-2 box-shadow"
              >
                <Card.Header className="mt-2 text-center">
                  <h4 className="my-4">Sri Lanka Tour 05 Nights 06 Days</h4>
                  <Card.Img
                    src="./img//tours/Colombo-galleface.jpg"
                    alt="Card image"
                  />
                </Card.Header>
                <Card.Body>
                  <Card.Title className="landing-textdropshadow"></Card.Title>
                  <Card.Text className="tourscardtextheight">
                    <ul>
                      <li>COLOMBO</li>
                      <li>PINNAWALA</li>
                      <li>TEMPLE OF THE SACRED TOOTH RELIC</li>
                      <li>ROYAL BOTANICAL GARDEN, PERADENIYA</li>
                      <li>NUWARAELIYA</li>
                      <li>KITULGALA</li>
                      <li>BENTOTA</li>
                      <li>KOGODA TUTTLE HATCHERY</li>
                      <li>MADU GANGA</li>
                    </ul>
                  </Card.Text>
                </Card.Body>
                <Card.Footer>
                  <Link
                    to="/fivenightstour"
                    className="nav-link active py-2 d-block text-white text-center"
                  >
                    More Details
                  </Link>
                </Card.Footer>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col>
              <Card
                data-aos="fade-down-left"
                data-aos-easing="ease-in-out-quart"
                data-aos-offset="-10"
                data-aos-duration="1500"
                data-aos-anchor-placement="top-bottom"
                className="text-left m-2 box-shadow"
              >
                <Card.Header className="mt-2 text-center">
                  <h4 className="my-4">Sri Lanka Tour 06 Nights 07 Days</h4>
                  <Card.Img
                    src="./img//tours/Colombo_airport.jpg"
                    alt="Card image"
                  />
                </Card.Header>

                <Card.Body>
                  <Card.Title className="landing-textdropshadow"></Card.Title>
                  <Card.Text className="tourscardtextheight1">
                    <ul>
                      <li>COLOMBO</li>
                      <li>PINNAWALA</li>
                      <li>TEMPLE OF THE SACRED TOOTH RELIC</li>
                      <li>ROYAL BOTANICAL GARDEN, PERADENIYA</li>
                      <li>NUWARAELIYA</li>
                      <li>ELLA</li>
                      <li>YALA</li>
                      <li>BENTOTA</li>
                      <li>KOGODA TUTTLE HATCHERY</li>
                      <li>MADU GANGA</li>
                    </ul>
                  </Card.Text>
                </Card.Body>
                <Card.Footer>
                  <Link
                    to="/sixnightstour"
                    className="nav-link active py-2 d-block text-white text-center"
                  >
                    More Details
                  </Link>
                </Card.Footer>
              </Card>
            </Col>
            <Col>
              <Card
                data-aos="fade-up-left"
                data-aos-easing="ease-in-out-quart"
                data-aos-offset="-10"
                data-aos-duration="1500"
                data-aos-anchor-placement="top-bottom"
                className="text-left m-2 box-shadow"
              >
                <Card.Header className="mt-2 text-center">
                  <h4 className="my-4">Cover Sri Lanka Tour</h4>
                  <Card.Img
                    src="./img//tours/ella-kandy-train.jpg"
                    alt="Card image"
                  />
                </Card.Header>

                <Card.Body>
                  <Card.Text className="tourscardtextheight2">
                    <ul>
                      <li>KALPITIYA BEACH AND LAGOON</li>
                      <li>WILPATTU NATIONAL PARK, WIND/KITE SURFING</li>
                      <li>ANURADHAPURA RUINS AND BUDDHIST TEMPLES </li>
                      <li>
                        NILAVELI BEACH AND KONASWARAM HINDU TEMPLE, TRINCOMALEE
                      </li>
                      <li>NILAVELI BEACH AND PIGEON ISLAND NATIONAL PARK </li>
                      <li>DAMBULLA CAVE TEMPLES </li>
                      <li>
                        SIGIRIYA ROCK FORTRESS, PIDURANGALA ROCK AND ELEPHANT
                        SAFARI
                      </li>
                      <li>POLONNARUWA RUINS, LAKE AND CANAL </li>
                      <li>KANDY, TEMPLE OF THE TOOTH, FOREST ABOVE KANDY </li>
                      <li>
                        KANDY BOTANICAL GARDENS, MAHAWELI RIVER KAYAKING, FOLK
                        DANCE PERFORMANCE
                      </li>
                      <li>HIGHLANDS TRAIN AND BIKING </li>
                      <li>ASCENT OF ADAM’S PEAK </li>
                      <li>HIGHLANDS TRAIN TO HAPUTALE </li>
                      <li>HIKING AT LIPTON’S SEAT </li>
                      <li>
                        HIKE DOWN DEVIL’S STAIRCASE, DOWNHILL BIKING TO THE
                        COASTAL LOWLANDS
                      </li>
                      <li>
                        SNORKELING WITH SEA TURTLES AND KAYAKING – POLHENA BEACH
                        AND KOGGALA LAGOON (MATARA)
                      </li>
                      <li>GALLE WALKING TOUR AND BACKROADS CYCLING </li>
                    </ul>
                  </Card.Text>
                </Card.Body>
                <Card.Footer>
                  <Link
                    to="/coversrilankatour"
                    className="nav-link active py-2 d-block text-white text-center"
                  >
                    More Details
                  </Link>
                </Card.Footer>
              </Card>
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    );
  }
}

export default AllTours;

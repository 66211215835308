import React from "react";
import "./App.css";
import Header from "./components/header";
import Home from "./components/home";
import { Route, Switch } from "react-router-dom";
import AllTours from "./components/alltours";
import CoverSriLankaTour from "./components/coversrilankatour";
import CLHFooter from "./components/clhfooter";
import FiveNightsTour from "./components/tourfivenights";
import SixNightsTour from "./components/toursixnights"; 
import SpiritualTour from "./components/tourspiritual";
import ActivitiesDetails from "./components/activities";
import AboutUs from './components/aboutus';
import ContactUs from './components/contactus';
import Destinations from "./components/destinations";
import ScrollButton from './components/scrolltotop';

function App() {
  return (
    <div className="App">
      <div className="content">
      <Header></Header>
        <Switch>
          <Route path="/mostfamoustours" component={AllTours}></Route>
          <Route path="/coversrilankatour" component={CoverSriLankaTour}></Route>
          <Route path="/fivenightstour" component={FiveNightsTour}></Route>
          <Route path="/sixnightstour" component={SixNightsTour}></Route>
          <Route path="/spiritualtour" component={SpiritualTour}></Route>
          <Route path="/activities" component={ActivitiesDetails}></Route>
          <Route path="/destinations" component={Destinations}></Route>
          <Route path="/aboutus" component={AboutUs}></Route>
          <Route path="/contactus" component={ContactUs}></Route>
          <Route path="/" component={Home}></Route>
        </Switch>
      </div>
      <CLHFooter></CLHFooter>
      <ScrollButton></ScrollButton>
    </div>
  );
}

export default App;

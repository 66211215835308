import React, { Component } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import Card from "react-bootstrap/Card";
import MostFamousToursSlider from "./mostfamouttoursslider";
import { CardDeck } from "react-bootstrap";
import SigiriyaHotel from "./sigiriyahotel";
import AOS from "aos";
import "aos/dist/aos.css";
import Form from "./Form";
import HomeSlider from "./slider";
import { Link } from "react-router-dom";

class Home extends Component {
  state = {};

  constructor() {
    super();
    //document.getElementById("body").className = "bg-img bg-margin-hm";
  }

  render() {
    return (
      <React.Fragment>
        <HomeSlider></HomeSlider>
        <Row className="home-heading-bg home-shadow pt-3 mt-0 mb-0">
          <Col>
            <div
              data-aos="slide-right"
              data-aos-offset="200"
              data-aos-easing="ease-in-sine"
              data-aos-duration="300"
            >
              <h2 className="landing-textdropshadow-h">WHY SRI LANKA</h2>
            </div>
          </Col>
        </Row>
        {/* why sri lanka */}
        <Row
          id="whysrilanka"
          className="home-heading-bg home-shadow mt-0 mb-0 pb-2 whysrilanka-bg"
        >
          <Col>
            <Row className="text-justify pt-2 pb-2">
              <Col xs={12} md={7} className="ml-3">
                <Row>
                  <Col className="p-mr">
                    <p
                      data-aos="fade-up-right"
                      data-aos-offset="200"
                      data-aos-easing="ease-in-sine"
                      data-aos-duration="600"
                    >
                      Almost 10 years after the end of its civil war, Sri Lanka
                      has been named the best country in the world to visit in
                      2019 by Lonely Planet.
                    </p>
                    <p
                      data-aos="fade-up-right"
                      data-aos-offset="200"
                      data-aos-easing="ease-in-sine"
                      data-aos-duration="600"
                    >
                      This small island off the south coast of India has been
                      growing in popularity over the last couple of years. In
                      2017, it welcomed 2.1 million tourists – its highest
                      number ever and a huge increase from the 447,890 that
                      visited ten years ago.
                    </p>
                    <p
                      data-aos="fade-up-right"
                      data-aos-offset="200"
                      data-aos-easing="ease-in-sine"
                      data-aos-duration="600"
                    >
                      If golden beaches, rising waves, misty mountains, mighty
                      elephants, stealthy leopards, giant whales, a majestic
                      past, lovely tea and warm smiles could sum up a country,
                      that would be Sri Lanka.
                    </p>
                  </Col>
                </Row>
              </Col>
              <Col xs={12} md={4} className="ml-auto mr-auto">
                <Image
                  data-aos="fade-up-left"
                  data-aos-offset="200"
                  data-aos-easing="ease-in-sine"
                  data-aos-duration="600"
                  className="srilanka-img"
                  src="./img/sri-lanka-map.png"
                  fluid
                />
              </Col>
            </Row>
          </Col>
        </Row>
        {/* Book your round trip */}
        <Row
          id="booking"
          className="home-heading-bg mt-0 pt-3 mb-0 home-shadow"
        >
          <Col>
            <Row className="">
              <Col>
                <h2
                  data-aos="slide-right"
                  data-aos-offset="200"
                  data-aos-easing="ease-in-sine"
                  data-aos-duration="300"
                  className="landing-textdropshadow-h"
                >
                  BOOK YOUR ROUND TRIP
                </h2>
              </Col>
            </Row>
            <Row className="mb-0 bookyourtrip">
              <Col className="px-4">
                <Form></Form>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row className="mt-4 mb-2">
          <Col xs={12} md={4}>
            {/* <CardDeck> */}
            <Card
              data-aos="fade-up-left"
              data-aos-offset="200"
              data-aos-easing="ease-in-sine"
              data-aos-duration="600"
              className="text-center m-2 box-shadow"
            >
              <Card.Header>
                <MostFamousToursSlider className="m-1 mftsliderpic"></MostFamousToursSlider>
              </Card.Header>

              <Card.Body>
                <Card.Title className="landing-textdropshadow">
                  MOST FAMOUS TOURS
                </Card.Title>
                <Card.Text className="text-justify homeboxheight1">
                  Sri Lanka ranked No 1 travel destination in 2019. We offer
                  tour packages to Sri Lanka to experience the sunny golden
                  beaches, explore the cultural triangles UNESCO world heritage
                  sites and Wild life.
                </Card.Text>
              </Card.Body>
              <Card.Footer>
                <Link to="/mostfamoustours" className="py-2 d-block text-white">
                  More Details
                </Link>
              </Card.Footer>
            </Card>
          </Col>
          <Col xs={12} md={4}>
            <Card
              data-aos="slide-up"
              data-aos-offset="200"
              data-aos-easing="ease-in-sine"
              data-aos-duration="1000"
              className="text-center m-2 box-shadow"
            >
              <Card.Header>
                <SigiriyaHotel className="m-1 mftsliderpic"></SigiriyaHotel>
              </Card.Header>

              <Card.Body>
                <Card.Title className="landing-textdropshadow">
                  CLOUDZ SIGIRIYA
                </Card.Title>
                <Card.Text className="text-justify homeboxheight2">
                  Located in Sigiriya central province of Sri Lanka amidst the
                  UNESCO world heritage site, 5 km away from the world renowned
                  ancient “Sigiriya Rock”. Cloudz Sigiriya is well spread over a
                  privately owned eloquently Designed, well secured 1 acre land
                  area with lush green and plenty of space for guests enjoys
                  their holiday in a very tranquil environment. The resort
                  speaks of a very natural “back to nature” concept with cottage
                  style thatched roofed accommodation and consists of a
                  Reception, Restaurant, Kitchen, Staff Accommodation Drivers
                  Accommodation and a Vehicle Parking area. The property is
                  situated in the village Rangirigama which is 4 km off the main
                  Sigiriya road and just 1 kilo meter away from Jetwing Vil
                  Uyana Hotel.
                </Card.Text>
              </Card.Body>
              <Card.Footer>
                <a
                  href="http://cloudzhotels.com/cloudz-sigiriya.php"
                  target="_blank"
                  className="py-2 d-block text-white"
                >
                  Visit Web Site
                </a>
              </Card.Footer>
            </Card>
          </Col>
          <Col xs={12} md={4}>
            <Card
              data-aos="fade-up-right"
              data-aos-offset="200"
              data-aos-easing="ease-in-sine"
              data-aos-duration="600"
              className="text-center m-2 box-shadow"
            >
              <Card.Header>
                <img
                  className="w-100 text-center mftsliderpic mr-auto"
                  src="./img/our-transport.jpg"
                  alt="First slide"
                />
              </Card.Header>

              <Card.Body>
                <Card.Title className="landing-textdropshadow">
                  OUR TRANSPORT
                </Card.Title>
                <Card.Text className="text-justify homeboxheight3">
                  We offer Transport services for tourists and visitors to suit
                  their requirements and their budget. All our vehicle are fully
                  insured with comprehensive insurance policies. We provide
                  Luxury Cars, Vans & Jeeps with professional chauffeur guides.
                </Card.Text>
              </Card.Body>
              <Card.Footer>
                <a href="#" className="py-2 d-block text-white">
                  Book a Tour
                </a>
              </Card.Footer>
            </Card>
          </Col>
        </Row>
        {/* famous cloud brands */}
        <Row className="my-2">
          <Col className="my-3">
            <img
              data-aos="slide-left"
              data-aos-offset="200"
              data-aos-easing="ease-in-sine"
              data-aos-duration="1200"
              data-aos-delay="100"
              className="mftsliderpic"
              src="./img/brands/clh-logo.png"
              alt="First slide"
            />
          </Col>
          <Col className="my-3">
            <img
              data-aos="slide-right"
              data-aos-offset="200"
              data-aos-easing="ease-in-sine"
              data-aos-duration="600"
              className="mftsliderpic"
              src="./img/brands/cloudz-hotels-logo.png"
              alt="First slide"
            />
          </Col>
        </Row>
        {/* famous cloud brands */}
      </React.Fragment>
    );
  }
}

export default Home;

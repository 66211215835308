import React from "react";
import Joi from "joi-browser";

export default class extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formEmailSent: false,
      disbleBookingButton: false,
      template_params: {
        name: "",
        email: "",
        contactnumber: "",
        messege: ""
      },
      service_id: "default_service",
      template_id: "contactus",
      errors: {}
    };

    this.handleChangeName = this.handleChangeName.bind(this);
    this.handleChangeEmail = this.handleChangeEmail.bind(this);
    this.handleChangeContactNumber = this.handleChangeContactNumber.bind(this);
    this.handleChangeMessege = this.handleChangeMessege.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChangeName(event) {
    /* this.setState({
      name: event.target.value
    }); */
    const errors = {...this.state.errors};
    const errorMessege = this.validateProperty(event.target);
    // console.log(errorMessege);
    if (errorMessege) errors[event.target.name] = errorMessege;
    else delete errors[event.target.name];
    
    const template_params = {...this.state.template_params};
    template_params["name"] = event.target.value;

    this.setState({
      template_params: template_params,
      errors: errors
    });

  }

  handleChangeEmail(event) {
    const errors = {...this.state.errors};
    const errorMessege = this.validateProperty(event.target);
    // console.log(errorMessege);
    if (errorMessege) errors[event.target.name] = errorMessege;
    else delete errors[event.target.name];
    
    const template_params = {...this.state.template_params};
    template_params["email"] = event.target.value;

    this.setState({
      template_params: template_params,
      errors: errors
    });
  }

  handleChangeContactNumber(event) {
    const errors = {...this.state.errors};
    const errorMessege = this.validateProperty(event.target);
    // console.log(errorMessege);
    if (errorMessege) errors[event.target.name] = errorMessege;
    else delete errors[event.target.name];
    
    const template_params = {...this.state.template_params};
    template_params["contactnumber"] = event.target.value;

    this.setState({
      template_params: template_params,
      errors: errors
    });
  }

  handleChangeMessege(event) {
    const errors = {...this.state.errors};
    const errorMessege = this.validateProperty(event.target);
    // console.log(errorMessege);
    if (errorMessege) errors[event.target.name] = errorMessege;
    else delete errors[event.target.name];
    
    const template_params = {...this.state.template_params};
    template_params["messege"] = event.target.value;

    this.setState({
      template_params: template_params,
      errors: errors
    });
  }

  schema = Joi.object({
    name: Joi.string().required().min(3).max(100).label('Name'),
    email: Joi.string().required()
        .email({ minDomainSegments: 2, tlds: { allow: ['com', 'net','org', 'lk',, 'net'] } }).label('Email'),
    messege: Joi.string().required().min(10).max(1000).label('Messege')

  });

  handleSubmit(event) {
    event.preventDefault();

    const errors = this.validate();

    this.setState({
      errors
    });

    if (errors) return;

    this.sendFeedback();

    this.setState({
      formSubmitted: true
    });
  }

  validateProperty = input => {

    if(input.name === "name")
    {
      if(input.value.trim() === "") return "User Name is required."
    }
   
    if(input.name === "email")
    {
      if(input.value.trim() === "") return "Emails is required."
    }

    if(input.name === "messege")
    {
      if(input.value.trim() === "") return "Messege is required."
    }

  }


  validate = () => {
    
    const errors = {};
    const template_params = {...this.state.template_params};
    

    const errorresult = Joi.validate(template_params,this.schema,{ abortEarly:false, allowUnknown: true } );

    if (errorresult && errorresult.error)
    {
      for (let item of errorresult.error.details)
      {
        errors[item.path[0]] = item.message;
        /* console.log("item.path[0]",item.path[0]);
        console.log("item.message",item.message); */
      }

    }
  
    return Object.keys(errors).length === 0 ? null : errors;

  }

  sendFeedback() {

    const template_params_temp = {...this.state.template_params};

    let template_params = {
      name: template_params_temp.name,
      contact_email: template_params_temp.email,
      contact_number: template_params_temp.contactnumber,
      messege: template_params_temp.messege
    };

    window.emailjs
      .send(this.state.service_id, this.state.template_id, template_params)
      .then(res => {
        alert(
          "Thank you contacting us. We will get back to you as soon as possible !!"
        );
        this.setState({
          formEmailSent: true,
          disbleBookingButton: true
        });
      })
      // Handle errors here however you like
      .catch(err => console.error("Failed to send feedback. Error: ", err));
  }

  render() {
    return (
      <form
        id="booking_form"
        onSubmit={this.handleSubmit}
        data-aos="fade-in"
        data-aos-offset="200"
        data-aos-easing="ease-in-cubic"
        data-aos-duration="1500"
        data-aos-delay="100"
        className="home-heading-bg"
      >
        {/* first row */}
        <div className="row form-row">
          <div className="col align-middle m-3">
            <span className="form-label"><input
                type="text"
                name="name"
                onChange={this.handleChangeName}
                className="form-control"
                placeholder="Your Name"
              /></span>
              {this.state.errors && this.state.errors.name && <span className="error-box2">{this.state.errors.name}</span> }
          </div>
        </div>
        <div className="row form-row">
          <div className="col align-middle m-3">
            <span className="form-label"><input
                type="text"
                name="email"
                onChange={this.handleChangeEmail}
                className="form-control"
                placeholder="Your E-mail"
              /></span>
          {this.state.errors && this.state.errors.email && <span className="error-box2">{this.state.errors.email}</span> }
          </div>
        </div>
        <div className="row form-row">
          <div className="col align-middle m-3">
            <span className="form-label"><input
                type="text"
                name="contactnumber"
                onChange={this.handleChangeContactNumber}
                className="form-control"
                placeholder="Your Contact Number"
              /></span>
          {this.state.errors && this.state.errors.contactnumber && <span className="error-box2">{this.state.errors.contactnumber}</span> }
          </div>
        </div>
        <div className="row form-row">
          <div className="col align-top m-3">
            <span className="form-label"><input
                type="textarea"
                name="messege"
                onChange={this.handleChangeMessege}
                className="form-control contactus-meggege-inputtextarea"
                placeholder="Your Messege"
              /></span>
          {this.state.errors && this.state.errors.messege && <span className="error-box2">{this.state.errors.messege}</span> }
          </div>
        </div>
        <div className="row form-row text-left">
          <div className="col m-4">
            <input
              type="submit"
              value="Send"
              className="btn btn-secondary"
              placeholder="Send Messege"
              disabled = { this.state.disbleBookingButton }
            />
          </div>
          
        </div>
      </form>
    );
  }

  handleChange(event) {
    this.setState({ feedback: event.target.value });
  }
}

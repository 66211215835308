import React from "react";
import Joi from "joi-browser";

export default class extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formEmailSent: false,
      disbleBookingButton: false,
      template_params: {
        name: "",
        email: "",
        contactnumber: "",
        budget: "",
        noofpax: "0",
        noofdays: this.props.no_of_days,
        noofnights: this.props.no_of_nights,
        hotelcategory: "",
        tourname: this.props.tourname
      },
      errors: {},
      service_id: "default_service",
      template_id: "bookingatour"
    };

    this.handleChangeName = this.handleChangeName.bind(this);
    this.handleChangeEmail = this.handleChangeEmail.bind(this);
    this.handleChangeNoofPax = this.handleChangeNoofPax.bind(this);
    this.handleChangeHotelCategory = this.handleChangeHotelCategory.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChangeName(event) {

    const errors = {...this.state.errors};
    const errorMessege = this.validateProperty(event.target);
    
    if (errorMessege) errors[event.target.name] = errorMessege;
    else delete errors[event.target.name];
    
    const template_params = {...this.state.template_params};
    template_params["name"] = event.target.value;

    this.setState({
      template_params: template_params,
      errors: errors
    });
  }

  handleChangeEmail(event) {

    const errors = {...this.state.errors};
    const errorMessege = this.validateProperty(event.target);
    // console.log(errorMessege);
    if (errorMessege) errors[event.target.name] = errorMessege;
    else delete errors[event.target.name];
    
    const template_params = {...this.state.template_params};
    template_params["email"] = event.target.value;

    this.setState({
      template_params: template_params,
      errors: errors
    });
  }

  handleChangeNoofPax(event) {

    const errors = {...this.state.errors};
    const errorMessege = this.validateProperty(event.target);
    // console.log(errorMessege);
    if (errorMessege) errors[event.target.name] = errorMessege;
    else delete errors[event.target.name];

    const template_params = {...this.state.template_params};
    template_params["noofpax"] = event.target.value;

    this.setState({
      template_params: template_params,
      errors: errors
    });

  }

  handleChangeTourName(event) {
    this.setState({
        tourname: event.target.value
    });
  }

  handleChangeHotelCategory(event) {

    const errors = {...this.state.errors};
    const errorMessege = this.validateProperty(event.target);
    
    if (errorMessege) errors[event.target.name] = errorMessege;
    else delete errors[event.target.name];

    const template_params = {...this.state.template_params};
    template_params["hotelcategory"] = event.target.value;

    this.setState({
      template_params: template_params,
      errors: errors
    });

  }

  schema = Joi.object({
    name: Joi.string().required().min(3).max(100).label('Name'),
    email: Joi.string().required()
        .email({ minDomainSegments: 2, tlds: { allow: ['com', 'net','org', 'lk',, 'net'] } }).label('Email')
  });

  validateProperty = input => {

    if(input.name === "name")
    {
      if(input.value.trim() === "") return "User Name is required."
    }
   
    if(input.name === "email")
    {
      if(input.value.trim() === "") return "Emails is required."
    }

    if(input.name === "noofpax")
    {
      if(input.value.trim() === "" || input.value.trim() === 'Please Select No of Pax') return "No of Pax is required."
    }

    if(input.name === "hotelcategory")
    {
      if(input.value.trim() === "" || input.value.trim() === 'Please Select Hotel Category') return "Hotel Category is required."
    }

  }


  validate = () => {
    
    const errors = {};
    const template_params = {...this.state.template_params};

    const errorresult = Joi.validate(template_params,this.schema,{ abortEarly:false, allowUnknown: true } );

    if (errorresult && errorresult.error)
    {
      for (let item of errorresult.error.details)
      {
        errors[item.path[0]] = item.message;
        /* console.log("item.path[0]",item.path[0]);
        console.log("item.message",item.message); */
      }

    }
  
    if (template_params.noofpax === '0' || template_params.noofpax === 'Please Select No of Pax')
    {
      errors.noofpax = "No of Pax is required.";
    }

    if (template_params.hotelcategory === '' || template_params.hotelcategory === 'Please Select Hotel Category')
    {
      errors.hotelcategory = "Hotel Category is required.";
    } 

    return Object.keys(errors).length === 0 ? null : errors;

  }

  handleSubmit(event) {
    event.preventDefault();

    const errors = this.validate();

    this.setState({
      errors
    });

    if (errors) return;

    this.sendFeedback();

    this.setState({
      formSubmitted: true
    });
  }

  sendFeedback() {

    const template_params_temp = {...this.state.template_params};

    let template_params = {
      name: template_params_temp.name,
      contact_email: template_params_temp.email,
      contact_number: "N/A",
      _budget: "N/A",
      no_of_pax: template_params_temp.noofpax,
      no_of_days: template_params_temp.noofdays,
      no_of_nights: template_params_temp.noofnights,
      hotel_category: template_params_temp.hotelcategory,
      _tourname : template_params_temp.tourname
    };

    window.emailjs
      .send(this.state.service_id, this.state.template_id, template_params)
      .then(res => {
        alert(
          "Thank you for your Booking Request. We will get back to you as soon as possible !!"
        );
        this.setState({
          formEmailSent: true,
          disbleBookingButton: true
        });
      })
      // Handle errors here however you like
      .catch(err => console.error("Failed to send feedback. Error: ", err));
  }

  render() {
    return (
      <form
        id="booking_form"
        onSubmit={this.handleSubmit}
        data-aos="fade-in"
        data-aos-offset="200"
        data-aos-easing="ease-in-cubic"
        data-aos-duration="1500"
        data-aos-delay="100"
      >
        {/* first row */}
        <div className="row form-row">
          <div className="col m-3">
            <span className="form-label booking-row-width booking-align-left booking-header-text">
              Name
            </span>
          </div>
          <div className="col m-3">
            <span className="form-label booking-row-width booking-align-left booking-header-text">
              Email
            </span>
          </div>
        </div>
        <div className="row form-row">
          <div className="col m-3">
            <span className="booking-row-width">
              <input
                type="text"
                name="name"
                onChange={this.handleChangeName}
                className="form-control"
                placeholder="Your Name"
              />
            {this.state.errors && this.state.errors.name && <span className="error-box">{this.state.errors.name}</span> }
            </span>
          </div>
          <div className="col m-3">
            <span className="booking-row-width">
              <input
                type="text"
                name="name"
                onChange={this.handleChangeEmail}
                className="form-control"
                placeholder="Your Email"
              />
            </span>
            {this.state.errors && this.state.errors.email && <span className="error-box">{this.state.errors.email}</span> }
          </div>
        </div>
        {/* third row */}
        <div className="row form-row">
        <div className="col m-3">
            <span className="form-label booking-row-width booking-align-left booking-header-text">
              No of Pax
            </span>
          </div>
          <div className="col m-3">
            <span className="form-label booking-row-width booking-align-left booking-header-text">
              Hotel Category
            </span>
          </div>
        </div>
        {/* sdsds */}
        <div className="row form-row">
        <div className="col m-3">
            <span className="booking-row-width">
              <select
                className="form-control"
                id="noofpax"
                name="noofpax"
                onChange={this.handleChangeNoofPax}
              >
                <option>Please Select No of Pax</option>
                <option>1</option>
                <option>2</option>
                <option>3</option>
                <option>4</option>
                <option>5</option>
                <option>6</option>
                <option>7</option>
                <option>8</option>
                <option>9</option>
                <option>10</option>
                <option>10+</option>
              </select>
            </span>
            {this.state.errors && this.state.errors.noofpax && <span className="error-box">{this.state.errors.noofpax}</span> }
          </div>
          <div className="col m-3">
            <span className="booking-row-width">
              <select
                className="form-control"
                id="hotelcategory"
                name="hotelcategory"
                onChange={this.handleChangeHotelCategory}
              >
                <option>Please Select Hotel Category</option>
                <option>3 Star</option>
                <option>4 Star</option>
                <option>5 Star</option>
              </select>
            </span>
            {this.state.errors && this.state.errors.hotelcategory && <span className="error-box">{this.state.errors.hotelcategory}</span> }
          </div>
        </div>
        <div className="row form-row mt-3 mb-3">
          <div className="col m-3">&nbsp;</div>
          <div className="col m-3 text-center">
            <input
              type="submit"
              value="Send Inquiry"
              className="btn btn-secondary"
              placeholder="Send Inquiry"
              disabled = { this.state.disbleBookingButton }
            />
          </div>
          <div className="col m-3">&nbsp;</div>
        </div>
      </form>
    );
  }

  handleChange(event) {
    this.setState({ feedback: event.target.value });
  }
}

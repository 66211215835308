import React, { Component, ReactNode } from "react";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import OwlCarousel, { Options } from "react-owl-carousel";
import { Animated } from "react-animated-css";

class KandySlider extends Component {
  state = {
    options: {
      loop: true,
      margin: 10,
      nav: false,
      autoplay: true,
      animateIn: "slideInLeft",
      center: true,
      responsive: {
        0: {
          items: 1
        },
        600: {
          items: 2
        },
        1000: {
          items:3
        }
      }
    },

    items: [
      <div className="item">
        <img
          className="d-block w-100"
          src="./img/tours/kandy-sri-lanka-dalada-maligawa.jpg"
          alt="First slide"
        />
      </div>,
      <div className="item">
        <img
          className="d-block w-100"
          src="./img/tours/temple-of-the-tooth-kandy.jpg"
          alt="First slide"
        />
      </div>,
      <div className="item">
        <img
          className="d-block w-100"
          src="./img/tours/young-elephants-pinnawala.jpg"
          alt="First slide"
        />
      </div>,
      <div className="item">
        <img
          className="d-block w-100"
          src="./img/tours/Peradeniya-Botanical-Gardens-in-Kandy.jpg"
          alt="First slide"
        />
      </div>,
       <div className="item">
       <img
         className="d-block w-100"
         src="./img/tours/traditional-dance-performance-kandy.jpg"
         alt="First slide"
       />
     </div>,
      <Animated
        animationIn="bounceInLeft"
        animationOut="fadeOut"
        isVisible={true}
      >
       <div className="item">
       <img
         className="d-block w-100"
         src="./img/tours/kandyan-dance-show.jpg"
         alt="First slide"
       />
     </div>
      </Animated>
    ],

  };

  render() {
    return (
      <React.Fragment>
        <div>
          <OwlCarousel className="owl-theme" {...this.state.options}>
            {this.state.items}
          </OwlCarousel>
        </div>
      </React.Fragment>
    );
  }

  addItem = () => {
    const { items } = this.state;
    items.push(
      <div className="item" key={items.length + 1}>
        <h4>{items.length + 1}</h4>
      </div>
    );

    this.setState({ items });
  };

  deleteItem = () => {
    const { items, options } = this.state;
    items.pop();

    options.loop = items.length >= 5;
    this.setState({ items, options });
  };

}

export default KandySlider;

import React, { Component } from "react";
import Carousel from "react-bootstrap/Carousel";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap/Button";

class MostFamousToursSlider extends Component {
  state = {};
  render() {
    return (
      <React.Fragment>
        <Carousel interval="3000" pauseOnHover="true">
          <Carousel.Item>
            <img
              className="d-block w-100"
              src="./img//tours/ella-kandy-train.jpg"
              alt="First slide"
            />
            <Carousel.Caption className="box-m">
              <h3 className="text-white famoustour-mobilefont-size">Cover Sri Lanka Tour</h3>
              <Link
                to="/coversrilankatour"
                className="nav-link active py-2 text-white d-block text-center"
              >
                More Details
              </Link>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block w-100"
              src="./img/tours/Ella-Rock-Sri-Lanka.jpg"
              alt="Third slide"
            />
            <Carousel.Caption className="box-m">
              <h3 className="text-white famoustour-mobilefont-size">Sri Lanka Tour 05 Nights 06 Days</h3>
              <Link
                to="/fivenightstour"
                className="nav-link active py-2 text-white d-block text-center"
              >
                More Details
              </Link>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block w-100"
              src="./img/tours/safari-yala.jpg"
              alt="Third slide"
            />
            <Carousel.Caption className="box-m">
              <h3 className="text-white famoustour-mobilefont-size">Sri Lanka Tour 06 Nights 07 Days</h3>
              <Link
                to="/sixnightstour"
                className="nav-link active py-2 text-white d-block text-center"
              >
                More Details
              </Link>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block w-100"
              src="./img/tours/seetha-amman-temple.jpg"
              alt="Third slide"
            />
            <Carousel.Caption className="box-m">
              <h3 className="text-white famoustour-mobilefont-size">Hindu Tour 04 Nights 05 Days</h3>
              <Link
                to="/spiritualtour"
                className="nav-link active py-2 d-block text-white text-center"
              >
                More Details
              </Link>
            </Carousel.Caption>
          </Carousel.Item>
          
        </Carousel>
      </React.Fragment>
    );
  }
}

export default MostFamousToursSlider;

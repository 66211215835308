import React, { Component } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";

class AboutUs extends Component {
  state = {};
  
  constructor()
  {
    super();
    document.getElementById("body").className = "bg-img bg-margin";
  }

  render() {
    return (
      <React.Fragment>
        <Container className="p-5">
          <Row>
            <Col>
              <p>&nbsp;</p>
            </Col>
          </Row>
          <Row>
            <Col>
              <h3
                data-aos="fade-up-right"
                data-aos-offset="200"
                data-aos-easing="ease-in-out"
                data-aos-duration="1000"
                data-aos-anchor-placement="top-bottom"
              >
                ABOUT US
              </h3>
            </Col>
          </Row>
          <Row>
            <Col>
              <p>&nbsp;</p>
            </Col>
          </Row>
          <Row>
            <Col
              className="text-justify"
              data-aos="fade"
              data-aos-offset="0"
              data-aos-easing="ease-in-out"
              data-aos-duration="2000"
              data-aos-anchor-placement="top-bottom"
            >
              <p>
                Cloudz Leisure Holidays (Pvt) Ltd. “Holistic solutions to all
                your hospitality needs under one roof”
              </p>
              <p>
                An emerging modern-day organization established in year 2017
                which is built around the engaging fundamentals of most
                essential segments of the hospitality industry such as
                Hotels/Resorts, Property Management Systems (PMS), Cleaning &
                Hygiene Solutions, Freight & Logistic Solutions, Travel &
                Leisure Solutions, Property Development / reengineering and
                Financial Advisory and Importers and distributors of quality
                hotel linen. Company founded by a …., th, generation
                entrepreneur with a wealth of experience in the financial/audit
                sector, hospitality sector in Sri Lanka and overseas.
              </p>
            </Col>
          </Row>
          {/* vision */}
          <Row>
            <Col>
              <h3
                data-aos="fade-up-right"
                data-aos-offset="0"
                data-aos-easing="ease-in-out"
                data-aos-duration="1000"
                data-aos-anchor-placement="top-bottom"
              >
                VISION
              </h3>
            </Col>
          </Row>
          <Row>
            <Col>
              <p>&nbsp;</p>
            </Col>
          </Row>
          <Row>
            <Col
              className="text-justify"
              data-aos="fade"
              data-aos-offset="0"
              data-aos-easing="ease-in-out"
              data-aos-duration="2000"
              data-aos-anchor-placement="top-bottom"
            >
              <p>
                To become a leader in providing hospitality related services
                with a clear focus to quality in products, timely and committed
                service delivery system before and after sales using the best
                available resources, tools and modern technology.
              </p>
            </Col>
          </Row>
          {/* mission */}
          <Row>
            <Col>
              <h3
                data-aos="fade-up-right"
                data-aos-offset="0"
                data-aos-easing="ease-in-out"
                data-aos-duration="1000"
                data-aos-anchor-placement="top-bottom"
              >
                MISSION
              </h3>
            </Col>
          </Row>
          <Row>
            <Col>
              <p>&nbsp;</p>
            </Col>
          </Row>
          <Row>
            <Col
              className="text-justify"
              data-aos="fade"
              data-aos-offset="0"
              data-aos-easing="ease-in-out"
              data-aos-duration="2000"
              data-aos-anchor-placement="top-bottom"
            >
              <p>
                We constantly commit ourselves to excellence in our aptitudes,
                in all our business endeavors and always strive to deliver our
                best in products & services with a positive mind set to exceed
                expectations of our guests/customers and partners in an honest
                and an ethical manner focusing not only on profit but on lasting
                relationships.
              </p>
            </Col>
          </Row>
          <Row>
            <Col>
              <p>&nbsp;</p>
            </Col>
          </Row>
          <Row>
            <Col
              data-aos="fade"
              data-aos-offset="0"
              data-aos-easing="ease-in-out"
              data-aos-duration="2000"
              data-aos-anchor-placement="top-bottom"
            >
              <Link to="/contactus" className="nav-link mx-3">
                <Button
                  className="btn btn-secondary"
                  variant="secondary"
                  size="md"
                >
                  Get in Touch
                </Button>
              </Link>
            </Col>
          </Row>
          <Row>
            <Col>
              <p>&nbsp;</p>
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    );
  }
}

export default AboutUs;

import React, { Component } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

class CLHFooter extends Component {
  state = {};
  render() {
    return (
      <React.Fragment>
        <footer
          data-aos="fade-in"
          data-aos-offset="200"
          data-aos-easing="ease-in-sine"
          data-aos-duration="500"
          data-aos-delay="100"
          className="mt-3"
        >
          <Row className="footer-bg-light m-2">
            <Col className="m-1">
              <h4>Corporate Office</h4>
              <ul className="list-unstyled">
                <li className="m-1">11, Hunter Street, Kew, VIC,</li>
                <li className="m-1">Melbourne,</li>
                <li className="m-1">Australia</li>
                <li className="m-1">Tel: 0061 432 434 565</li>
              </ul>
            </Col>
            <Col className="m-1">
              <h4>Sri Lanka</h4>
              <ul className="list-unstyled">
                <li className="m-1">Executive Center, West Tower,</li>
                <li className="m-1">Level 35, World Trade Center,</li>
                <li className="m-1">Colombo 01, Sri Lanka</li>
                <li className="m-1">Tel: 0094 117 230 200</li>
              </ul>
            </Col>
            <Col className="m-1">
              <h4>Maldives</h4>
              <ul className="list-unstyled">
                <li className="m-1">1/29, 6th Floor,</li>
                <li className="m-1">Chaandhanee Magu, Male,</li>
                <li className="m-1">Republic of Maldives</li>
                <li className="m-1">Tel: 0096 332 2044</li>
              </ul>
            </Col>
            <Col className="m-1">
              <h4 className="ftco-heading-2">Contact Information</h4>
              <ul className="list-unstyled">
                <li className="m-1">63/3/1, Piliyandala Road,</li>
                Maharagama, Sri Lanka.
                <li className="m-1">0094 117 580 600 </li>
                <li className="m-1">
                 <a
                    href="mailto:operations@cloudzleisure.com "
                    className="d-block"
                  >
                    operations@cloudzleisure.com 
                  </a>
                </li>
              </ul>
            </Col>
          </Row>
          <Row className="footer-bg text-center">
            <Col className="mb-2 text-center mt-2">
              <p className="text small footer-text">
                Copyright &copy; 2019 All rights reserved |{" "}
                <i className="icon-heart" aria-hidden="true"></i>{" "}
                <a
                  href="https://cloudzleisure.com/"
                  target="_blank"
                  className=""
                >
                  Cloudz Leisure Holidays
                </a>
              </p>
            </Col>
          </Row>
        </footer>
      </React.Fragment>
    );
  }
}

export default CLHFooter;

import React, { Component } from "react";
import Carousel from "react-bootstrap/Carousel";

class SigiriyaHotel extends Component {
  state = {};
  render() {
    return (
      <React.Fragment>
        <Carousel className="m-1">
          <Carousel.Item>
            <img
              className="d-block w-100"
              src="./img/sigiriya-hotel-1.jpg"
              alt="First slide"
            />
            {/* <Carousel.Caption>
              <h3>Welcome to Cloudz Sigiriya</h3>
            </Carousel.Caption> */}
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block w-100"
              src="./img/sigiriya-hotel-2.jpg"
              alt="Third slide"
            />

            {/* <Carousel.Caption>
              <h3>Habarana Safari</h3>
            </Carousel.Caption> */}
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block w-100"
              src="./img/sigiriya-hotel-3.jpg"
              alt="Third slide"
            />
            {/* <Carousel.Caption>
              <h3>Beach Tour</h3>
            </Carousel.Caption> */}
          </Carousel.Item>
        </Carousel>
      </React.Fragment>
    );
  }
}

export default SigiriyaHotel;

import React, { Component } from "react";
import Carousel from "react-bootstrap/Carousel";
import Button from "react-bootstrap/Button";
class HomeSlider extends Component {
  state = {};
  render() {
    return (
      <React.Fragment>
        <div className="home-shadow">
        <Carousel interval="2000" pauseOnHover="false">
          <Carousel.Item>
            <img
              className="d-block w-100"
              src="./img/slider/slider-2.jpg"
              alt="First slide"
            />
            <Carousel.Caption className="hide-slider-mobile">
              <div className="slider-button-align">
                <a className="slider-button-a" href="#booking">
                  <Button className="slider-button-bg" variant="secondary" size="md">
                    Inquire Now
                  </Button>
                </a>
              </div>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block w-100"
              src="./img/slider/slider-1.jpg"
              alt="Third slide"
            />

            <Carousel.Caption className="hide-slider-mobile">
            <div className="slider-button-align">
                <a className="slider-button-a" href="#booking">
                  <Button className="slider-button-bg" variant="secondary" size="md">
                    Inquire Now
                  </Button>
                </a>
              </div>
            </Carousel.Caption>
          </Carousel.Item>

          <Carousel.Item>
            <img
              className="d-block w-100"
              src="./img/slider/slider-3.jpg"
              alt="Third slide"
            />
            <Carousel.Caption className="hide-slider-mobile">
            <div className="slider-button-align">
                <a className="slider-button-a" href="#booking">
                  <Button className="slider-button-bg" variant="secondary" size="md">
                    Inquire Now
                  </Button>
                </a>
              </div>
            </Carousel.Caption>
          </Carousel.Item>

          <Carousel.Item>
            <img
              className="d-block w-100"
              src="./img/slider/slider-4.jpg"
              alt="Third slide"
            />
            <Carousel.Caption className="hide-slider-mobile">
            <div className="slider-button-align">
                <a className="slider-button-a" href="#booking">
                  <Button className="slider-button-bg" variant="secondary" size="md">
                    Inquire Now
                  </Button>
                </a>
              </div>
            </Carousel.Caption>
          </Carousel.Item>

          <Carousel.Item>
            <img
              className="d-block w-100"
              src="./img/slider/slider-5.jpg"
              alt="Third slide"
            />
            <Carousel.Caption className="hide-slider-mobile">
            <div className="slider-button-align">
                <a className="slider-button-a" href="#booking">
                  <Button className="slider-button-bg" variant="secondary" size="md">
                    Inquire Now
                  </Button>
                </a>
              </div>
            </Carousel.Caption>
          </Carousel.Item>
        </Carousel>
        </div>
      </React.Fragment>
    );
  }
}

export default HomeSlider;

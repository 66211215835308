import React, { Component } from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import { Link } from "react-router-dom";
import classnames from "classnames";

class Header extends Component {
  constructor(props) {
    super(props);

    this.state = {
      prevScrollpos: window.pageYOffset,
      visible: true,
      scrolling: false,
      navlinkclass: "mx-3 nav-bar-alt1"
    };
  }

  componentDidMount = () => {
    window.addEventListener("scroll", this.handleScroll, true);
  };

  componentWillUnmount = () => {
    window.removeEventListener("scroll", this.handleScroll, true);
  };

  handleScroll = event => {
    if (window.scrollY < 100) {
      document.getElementById("navbarclh").className =
        "navbar navbar-expand-md navbar-light fixed-top sc1";
      this.setState({ navlinkclass: "mx-3 nav-bar-alt1" });
    } else {
      document.getElementById("navbarclh").className =
        "navbar navbar-expand-md navbar-light fixed-top sc";
      document.getElementById("brandlogo").className = "logosmall";
      this.setState({ navlinkclass: "mx-3 nav-bar-alt" });
    }
    if (window.scrollY === 0 && this.state.scrolling === true) {
      this.setState({ scrolling: false });
    } else if (window.scrollY !== 0 && this.state.scrolling !== true) {
      this.setState({ scrolling: true });
    }
  };

  render() {
    return (
      <React.Fragment>
        <Navbar className="fixed-top sc1" id="navbarclh" expand="md">
          <Navbar.Brand href="/" className="ml-2">
            <img
              src="../img/clh-logo-sm.png"
              width=""
              height=""
              className="d-inline-block align-top logocls"
              alt="Cloudz Leisure Logo"
              id="brandlogo"
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav" className="nav-bar-font">
            <Nav className="mr-auto navbar-center ml-auto">
              <Link to="/" className={this.state.navlinkclass}>
                HOME
              </Link>
              <Link to="/destinations" className={this.state.navlinkclass}>
                DESTINATIONS
              </Link>
              <Link to="/activities" className={this.state.navlinkclass}>
                ACTIVITIES
              </Link>
              <Link to="/aboutus" className={this.state.navlinkclass}>
                ABOUT US
              </Link>
              <Link to="/contactus" className={this.state.navlinkclass}>
                CONTACT US
              </Link>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </React.Fragment>
    );
  }
}

export default Header;

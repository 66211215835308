import React from "react";
import Joi from "joi-browser";
import { jsxEmptyExpression } from "@babel/types";

export default class extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formEmailSent: false,
      disbleBookingButton: false,
      service_id: "default_service",
      template_id: "bookingatour",
      template_params: {
        name: "",
        email: "",
        contactnumber: "",
        budget: "",
        noofpax: "0",
        noofdays: "0",
        noofnights: "0",
        hotelcategory: ""
      },
      errors: {}
    };

    this.handleChangeName = this.handleChangeName.bind(this);
    this.handleChangeEmail = this.handleChangeEmail.bind(this);
    this.handleChangeContactNumber = this.handleChangeContactNumber.bind(this);
    this.handleChangeBudget = this.handleChangeBudget.bind(this);
    this.handleChangeNoofDays = this.handleChangeNoofDays.bind(this);
    this.handleChangeNoofPax = this.handleChangeNoofPax.bind(this);
    this.handleChangeNoOfNights = this.handleChangeNoOfNights.bind(this);
    this.handleChangeHotelCategory = this.handleChangeHotelCategory.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  
  handleChangeName(event) {

    const errors = {...this.state.errors};
    const errorMessege = this.validateProperty(event.target);
    
    if (errorMessege) errors[event.target.name] = errorMessege;
    else delete errors[event.target.name];
    
    const template_params = {...this.state.template_params};
    template_params["name"] = event.target.value;

    this.setState({
      template_params: template_params,
      errors: errors
    });
    
  }

  handleChangeEmail(event) {

    const errors = {...this.state.errors};
    const errorMessege = this.validateProperty(event.target);
    // console.log(errorMessege);
    if (errorMessege) errors[event.target.name] = errorMessege;
    else delete errors[event.target.name];
    
    const template_params = {...this.state.template_params};
    template_params["email"] = event.target.value;

    this.setState({
      template_params: template_params,
      errors: errors
    });
  }

  handleChangeContactNumber(event) {

    const errors = {...this.state.errors};
    const errorMessege = this.validateProperty(event.target);
    // console.log(errorMessege);
    if (errorMessege) errors[event.target.name] = errorMessege;
    else delete errors[event.target.name];

    const template_params = {...this.state.template_params};
    template_params["contactnumber"] = event.target.value;

    this.setState({
      template_params: template_params,
      errors: errors
    });
  }

  handleChangeBudget(event) {

    const errors = {...this.state.errors};
    const errorMessege = this.validateProperty(event.target);
    // console.log(errorMessege);
    if (errorMessege) errors[event.target.name] = errorMessege;
    else delete errors[event.target.name];

    const template_params = {...this.state.template_params};
    template_params["budget"] = event.target.value;

    this.setState({
      template_params: template_params,
      errors: errors
    });
  }

  handleChangeNoofPax(event) {

    const errors = {...this.state.errors};
    const errorMessege = this.validateProperty(event.target);
    // console.log(errorMessege);
    if (errorMessege) errors[event.target.name] = errorMessege;
    else delete errors[event.target.name];

    const template_params = {...this.state.template_params};
    template_params["noofpax"] = event.target.value;

    this.setState({
      template_params: template_params,
      errors: errors
    });
  }

  handleChangeNoofDays(event) {

    const errors = {...this.state.errors};
    const errorMessege = this.validateProperty(event.target);

    if (errorMessege) errors[event.target.name] = errorMessege;
    else delete errors[event.target.name];

    const template_params = {...this.state.template_params};
    template_params["noofdays"] = event.target.value;

    this.setState({
      template_params: template_params,
      errors: errors
    });
  }

  handleChangeNoOfNights(event) {

    const errors = {...this.state.errors};
    const errorMessege = this.validateProperty(event.target);

    if (errorMessege) errors[event.target.name] = errorMessege;
    else delete errors[event.target.name];

    const template_params = {...this.state.template_params};
    template_params["noofnights"] = event.target.value;

    this.setState({
      template_params: template_params,
      errors: errors
    });
  }

  handleChangeHotelCategory(event) {

    const errors = {...this.state.errors};
    const errorMessege = this.validateProperty(event.target);
    
    if (errorMessege) errors[event.target.name] = errorMessege;
    else delete errors[event.target.name];

    const template_params = {...this.state.template_params};
    template_params["hotelcategory"] = event.target.value;

    this.setState({
      template_params: template_params,
      errors: errors
    });
  }

  schema = Joi.object({
    name: Joi.string().required().min(3).max(100).label('Name'),
    email: Joi.string().required()
        .email({ minDomainSegments: 2, tlds: { allow: ['com', 'net','org', 'lk',, 'net'] } }).label('Email'),
    contactnumber: Joi.string().trim().required().regex(/^(\+|00)[0-9]{1,3}[0-9]{4,14}(?:x.+)?$/).label("Contact Number"),
    budget: Joi.number().required().min(100).max(1000000).label('Budget')

  });

  validateProperty = input => {

    if(input.name === "name")
    {
      if(input.value.trim() === "") return "User Name is required."
    }
   
    if(input.name === "email")
    {
      if(input.value.trim() === "") return "Emails is required."
    }

    if(input.name === "contactnumber")
    {
      if(input.value.trim() === "") return "Contact Number is required."
    }

    if(input.name === "budget")
    {
      if(input.value.trim() === "") return "Budget is required."
    }

    if(input.name === "noofpax")
    {
      if(input.value.trim() === "" || input.value.trim() === 'Please Select No of Pax') return "No of Pax is required."
    }

    if(input.name === "noofdays")
    {
      if(input.value.trim() === "" || input.value.trim() === 'Please Select No of Days') return "No of Days is required."
    }

    if(input.name === "noofnights")
    {
      if(input.value.trim() === "" || input.value.trim() === 'Please Select No of Nights') return "No of Nights is required."
    }

    if(input.name === "hotelcategory")
    {
      if(input.value.trim() === "" || input.value.trim() === 'Please Select Hotel Category') return "Hotel Category is required."
    }

  }


  validate = () => {
    
    const errors = {};
    const template_params = {...this.state.template_params};
    

    const errorresult = Joi.validate(template_params,this.schema,{ abortEarly:false, allowUnknown: true } );

    if (errorresult && errorresult.error)
    {
      for (let item of errorresult.error.details)
      {
        errors[item.path[0]] = item.message;
        /* console.log("item.path[0]",item.path[0]);
        console.log("item.message",item.message); */
      }

    }
  
    if (template_params.noofpax === '0' || template_params.noofpax === 'Please Select No of Pax')
    {
      errors.noofpax = "No of Pax is required.";
    }

    if (template_params.noofdays === '0' || template_params.noofdays === 'Please Select No of Days')
    {
      errors.noofdays = "No of Days is required.";
    }

    if (template_params.noofnights === '0' || template_params.noofnights === 'Please Select No of Nights')
    {
      errors.noofnights = "No of Nights is required.";
    }

    if (template_params.hotelcategory === '' || template_params.hotelcategory === 'Please Select Hotel Category')
    {
      errors.hotelcategory = "Hotel Category is required.";
    } 

    return Object.keys(errors).length === 0 ? null : errors;

  }

  handleSubmit(event) {
    event.preventDefault();

    const errors = this.validate();

    this.setState({
      errors
    });

    if (errors) return;

    this.sendFeedback();

    this.setState({
      formSubmitted: true
    });
  }

  sendFeedback() {

    const template_params_temp = {...this.state.template_params};

    let template_params = {
      name: template_params_temp.name,
      contact_email: template_params_temp.email,
      contact_number: template_params_temp.contactnumber,
      _budget: template_params_temp.budget,
      no_of_pax: template_params_temp.noofpax,
      no_of_days: template_params_temp.noofdays,
      no_of_nights: template_params_temp.noofnights,
      hotel_category: template_params_temp.hotelcategory,
      _tourname : "Custom Tour"
    };

    window.emailjs
      .send(this.state.service_id, this.state.template_id, template_params)
      .then(res => {
        alert(
          "An Email is sent. We will get back to you as soon as possible !!"
        );
        this.setState({
          formEmailSent: true,
          disbleBookingButton: true
        });
      })
      // Handle errors here however you like
      .catch(err => console.error("Failed to send feedback. Error: ", err));
  }

  render() {
    return (
      <form
        id="booking_form"
        onSubmit={this.handleSubmit}
        data-aos="fade-in"
        data-aos-offset="200"
        data-aos-easing="ease-in-cubic"
        data-aos-duration="1500"
        data-aos-delay="100"
      >
        {/* first row */}
        <div className="row form-row bookingnformrow1">
          <div className="col-sm-12 col-md-6 my-3">
            <div className="form-label booking-row-width booking-align-left booking-header-text">
              Name
            </div>
          </div>
          <div className="col-sm-12 col-md-6 my-3">
            <div className="form-label booking-row-width booking-align-left booking-header-text">
              Email
            </div>
          </div>
        </div>
        <div className="row form-row">
          <div className="col-sm-12 col-md-6 my-3">
            <div className="booking-row-width">
              <input
                type="text"
                name="name"
                onChange={this.handleChangeName}
                className="form-control"
                placeholder="Your Name"
              />
            </div>
            {this.state.errors && this.state.errors.name && <span className="error-box">{this.state.errors.name}</span> }
          </div>
          <div className="col-sm-12 col-md-6 my-3">
            <div className="booking-row-width">
              <input
                type="text"
                name="email"
                onChange={this.handleChangeEmail}
                className="form-control"
                placeholder="Your Email"
              />
              {this.state.errors && this.state.errors.email &&  <span className="error-box">{this.state.errors.email}</span> }
            </div>
          </div>
        </div>
        {/* second row */}
        <div className="row form-row bookingnformrow1">
          <div className="col-sm-12 col-md-6 my-3">
            <div className="form-label booking-row-width booking-align-left booking-header-text">
              Contact Number
            </div>
          </div>
          <div className="col-sm-12 col-md-6 my-3">
            <div className="form-label booking-row-width booking-align-left booking-header-text">
              Budget
            </div>
          </div>
        </div>
        <div className="row form-row">
          <div className="col-sm-12 col-md-6 my-3">
            <div className="booking-row-width">
              <input
                type="text"
                name="contactnumber"
                onChange={this.handleChangeContactNumber}
                className="form-control"
                placeholder="Your Contact Number"
              />
            </div>
            {this.state.errors && this.state.errors.contactnumber &&  <span className="error-box">{this.state.errors.contactnumber}</span> }
          </div>
          <div className="col-sm-12 col-md-6 my-3">
            <div className="booking-row-width">
              <div>
              <div className="input-group-text booking-row-width2 align-middle" id="basic-addon1"><strong>US $</strong> </div>
              <input
                type="text"
                name="budget"
                onChange={this.handleChangeBudget}
                className="form-control booking-row-width3"
                placeholder="Your Budget"
              />
              </div>
              {this.state.errors && this.state.errors.budget &&  <span className="error-box">{this.state.errors.budget}</span> }
            </div>
          </div>
        </div>
        {/* end of second row */}
        {/* third row */}
        <div className="row form-row bookingnformrow1">
        <div className="col-sm-12 col-md-3 my-3">
            <div className="form-label booking-row-width booking-align-left booking-header-text">
              No of Pax
            </div>
          </div>
          <div className="col-sm-12 col-md-3 my-3">
            <div className="form-label booking-row-width booking-align-left booking-header-text">
              No of Days
            </div>
          </div>
          <div className="col-sm-12 col-md-3 my-3">
            <div className="form-label booking-row-width booking-align-left booking-header-text">
              No of Nights
            </div>
          </div>
          <div className="col-sm-12 col-md-3 my-3">
            <div className="form-label booking-row-width booking-align-left booking-header-text">
              Hotel Category
            </div>
          </div>
        </div>
        {/* sdsds */}
        <div className="row form-row">
        <div className="col-sm-12 col-md-3 my-3">
            <div className="booking-row-width">
              <select
                className="form-control"
                id="noofpax"
                name="noofpax"
                onChange={this.handleChangeNoofPax}
              >
                <option>Please Select No of Pax</option>
                <option>1</option>
                <option>2</option>
                <option>3</option>
                <option>4</option>
                <option>5</option>
                <option>6</option>
                <option>7</option>
                <option>8</option>
                <option>9</option>
                <option>10</option>
                <option>10+</option>
              </select>
            </div>
            {this.state.errors && this.state.errors.noofpax &&  <span className="error-box">{this.state.errors.noofpax}</span> }
          </div>
          <div className="col-sm-12 col-md-3 my-3">
            <div className="booking-row-width">
              <select
                className="form-control"
                id="noofdays"
                name="noofdays"
                onChange={this.handleChangeNoofDays}
              >
                <option>Please Select No of Days</option>
                <option>1</option>
                <option>2</option>
                <option>3</option>
                <option>4</option>
                <option>5</option>
                <option>6</option>
                <option>7</option>
                <option>8</option>
                <option>9</option>
                <option>10</option>
                <option>10+</option>
              </select>
            </div>
            {this.state.errors && this.state.errors.noofdays &&  <span className="error-box">{this.state.errors.noofdays}</span> }
          </div>
          <div className="col-sm-12 col-md-3 my-3">
            <div className="booking-row-width">
              <select
                className="form-control"
                id="noOfNights"
                name="noofnights"
                onChange={this.handleChangeNoOfNights}
              >
                <option>Please Select No of Nights</option>
                <option>1</option>
                <option>2</option>
                <option>3</option>
                <option>4</option>
                <option>5</option>
                <option>6</option>
                <option>7</option>
                <option>8</option>
                <option>9</option>
                <option>10</option>
                <option>10+</option>
              </select>
            </div>
            {this.state.errors && this.state.errors.noofnights &&  <span className="error-box">{this.state.errors.noofnights}</span> }
          </div>
          <div className="col-sm-12 col-md-3 my-3">
            <div className="booking-row-width">
              <select
                className="form-control"
                id="hotelcategory"
                name="hotelcategory"
                onChange={this.handleChangeHotelCategory}
              >
                <option>Please Select Hotel Category</option>
                <option>2 Star</option>
                <option>3 Star</option>
                <option>4 Star</option>
                <option>5 Star</option>
                <option>Boutique or Luxury</option>
              </select>
            </div>
            {this.state.errors && this.state.errors.hotelcategory &&  <span className="error-box">{this.state.errors.hotelcategory}</span> }
          </div>
        </div>
        <div className="row form-row mt-3 mb-3">
          <div className="col m-3">&nbsp;</div>
          <div className="col m-3">
            <input
              type="submit"
              value="Create My Booking"
              className="btn btn-secondary"
              placeholder="Create My Booking"
              disabled = { this.state.disbleBookingButton }
            />
          </div>
          <div className="col m-3">&nbsp;</div>
        </div>
      </form>
    );
  }

  handleChange(event) {
    this.setState({ feedback: event.target.value });
  }
}

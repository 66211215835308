import React, { Component, ReactNode } from "react";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import OwlCarousel, { Options } from "react-owl-carousel";
import { Animated } from "react-animated-css";

class BentotaSlider extends Component {
  state = {
    options: {
      loop: true,
      margin: 10,
      nav: false,
      autoplay: true,
      animateIn: "slideInLeft",
      center: true,
      responsive: {
        0: {
          items: 1
        },
        500: {
          items: 2
        },
        500: {
          items: 3
        }
      }
    },

    items: [
      
      <div className="item">
        <img
          className="d-block w-100"
          src="./img/tours/bentota-beach.jpg"
          alt="First slide"
        />
      </div>,
      <div className="item">
        <img
          className="d-block w-100"
          src="./img/tours/Bentota_sr_lanka.jpg"
          alt="First slide"
        />
      </div>,
      <div className="item">
        <img
          className="d-block w-100"
          src="./img/tours/kosgoda-turtle-hatchery.jpg" 
          alt="First slide"
        />
      </div>,
      <div className="item">
        <img
          className="d-block w-100"
          src="./img/tours/Madu-Ganga-River-safari.jpg"
          alt="First slide"
        />
      </div>,
      <div className="item">
        <img
          className="d-block w-100"
          src="./img/tours/madu-river-boat-safari-sri-lanka-fish-therapy.jpg"
          alt="First slide"
        />
      </div>,
      <div className="item">
        <img
          className="d-block w-100"
          src="./img/tours/madu-river-boat-safari-sri-lanka-crocadile.jpg"
          alt="First slide"
        />
      </div>,
      <div className="item">
      <img
        className="d-block w-100"
        src="./img/tours/bentota.jpg"
        alt="First slide"
      />
    </div>
    ]
  };

  render() {
    return (
      <React.Fragment>
        <div>
          <OwlCarousel className="owl-theme" {...this.state.options}>
            {this.state.items}
          </OwlCarousel>
        </div>
      </React.Fragment>
    );
  }

  addItem = () => {
    const { items } = this.state;
    items.push(
      <div className="item" key={items.length + 1}>
        <h4>{items.length + 1}</h4>
      </div>
    );

    this.setState({ items });
  };

  deleteItem = () => {
    const { items, options } = this.state;
    items.pop();

    options.loop = items.length >= 5;
    this.setState({ items, options });
  };
}

export default BentotaSlider;
